import React from 'react';
import ThreeBlockComponent from "./Three";
import Two from './Two';
import { Container } from '@radix-ui/themes';
import { useMediaQuery } from 'react-responsive';
import MobileHome from './MobileHome';

const Home = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <Container>
      <div>
        {isMobile ? (
          <MobileHome/>
          
        ) : (<>
          <Two />
          <ThreeBlockComponent />
          </>
        )}
      </div>
    </Container>
  );
};

export default Home;
