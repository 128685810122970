import React from 'react'
import {Text,Flex,Box,TextField} from "@radix-ui/themes"

const InputP = ({ title,value,onChange}) => {
  return (
    <div>
      <>
        <Text >{title}</Text>
        <Flex mt="12px" mb="12px" direction="column" maxWidth="240px">
        <Box maxWidth="240px">
            <TextField.Root
            size="2"
            placeholder="..."
            name="Nom"
            value={value}
            onChange={(e) => onChange(e.target.value)}
            color="indigo"
            />
        </Box>
        </Flex>
    </>
    </div>
  )
}

export default InputP
