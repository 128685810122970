import React, { useEffect, useState } from 'react';
import { Grid, Box, Text, Card, Code } from '@radix-ui/themes';
import logo from './../assets/media/drapeau.png';

const ThreeBlockComponent = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Grid
      mb="50px"
      columns={isMobile ? '1fr' : 'repeat(2, 1fr)'}
      rows={isMobile ? 'repeat(4, auto)' : 'repeat(2, auto)'}
      gap="3"
      width="auto"
      style={{
        display: 'grid',
        gridTemplateAreas: isMobile
          ? `
            "square1"
            "square2"
            "rectangle"
            "rectangle"
          `
          : `
            "square1 rectangle"
            "square2 rectangle"
          `,
        height: 'auto', // Adjust the height as needed
      }}
    >
      <Card m="20px" gridArea="square1" style={{boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",}}>
        <div style={{ margin: '20px' }}>
          <Text size="6">
            <Code>AVE</Code> c’est quoi exactement ?
          </Text>
        </div>
        <div style={{ margin: '20px' }}>
          <Text size="2" weight="medium">
          Pour quelque motif de voyage que ce soit, tourisme, travail ou simplement un transit vers une autre destination passant par un aéroport canadien, cette autorisation de voyage électronique (AVE) est obligatoire. L’ensemble des étrangers qui désirent séjourner au Canada ont l’obligation de demander leur AVE, à l’exception des Canadiens avec une double nationalité ou des citoyens américains. Concernant la validité de l’AVE, si votre passeport expire et que vous le renouvelez, il faudra également renouveler votre autorisation de voyage électronique. En attendant, vous êtes autorisé à voyager librement sur le territoire canadien aussi longtemps que vous le souhaitez. Attention, votre séjour ne devra pas dépasser un semestre. Il est intéressant de savoir que la validité de l’AVE peut aller jusqu’à 5 ans.
          </Text>
        </div>
      </Card>
    
      <Card m="20px" mb="0px" gridArea="rectangle" style={{ gridRow: isMobile ? 'auto' : 'span 2', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' , boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",}}>
        <div style={{ marginTop: '20px',marginLeft:'20px' }}>
          <Text size="6">
            <Code>Comment</Code> demander une AVE a Canada ?
          </Text>
          <div style={{margin:"20px"}}><Text size="2" weight="medium">
          L’AVE ne pourra en aucun cas freiner votre voyage, car l’obtenir est particulièrement simple. Grâce à sa technologie, la demande peut maintenant être traitée en ligne en seulement quelques clics sur un ordinateur. Vous pourrez recevoir une réponse positive très rapidement sur votre adresse e-mail une fois le formulaire de demande d’AVE complété. Ce dernier est uniquement disponible en anglais et en français. Afin de le remplir correctement, les champs sont traduits en onze langues : en japonais, en italien, en espagnol et bien d’autres encore.

À la fin du remplissage du formulaire, vous devez régler immédiatement les frais de traitement de la demande d’AVE. Ce règlement se fera en ligne avec une carte de crédit classique : VISA, MasterCard, JCB, etc. À la fin de cette procédure, vous devrez attendre patiemment une réponse d’acceptation. Il est possible que vous ayez besoin d’envoyer d’autres documents afin que votre demande soit acceptée. Dans ce cas, notre équipe de professionnels vous donnera la marche à suivre au plus tard 3 jours suivant votre enregistrement.

Maintenant, fini les files d’attente pour demander un visa, vous avez la possibilité de le faire directement depuis chez vous. Une fois que vous disposerez de votre autorisation de voyage électronique, vous serez libre de circuler sur le territoire du Canada. Bon vol à vous et à votre famille !
          </Text></div>
        </div>
      </Card>

      <Card m="20px" gridArea="square2" style={{boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",}}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%', // Ensure the container takes the full height of the card
          }}
        >
          <img src={logo} alt="Logo" style={{ width: 'auto', height: 'auto' }} />
        </div>
      </Card>

      
    </Grid>
  );
};

export default ThreeBlockComponent;
