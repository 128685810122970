import React, { useEffect, useState } from 'react';
import { Space, Table, Tag, Result, Form, Input, Button, Popconfirm, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { API_URL } from '../config';

const App = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(true); // Assume authorized by default
  const [form] = Form.useForm();

  const fetchData = async () => {
    const accessToken = sessionStorage.getItem('accessToken');
    try {
      const response = await fetch(`${API_URL}/users/`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });
      if (response.ok) {
        const result = await response.json();
        setData(result);
      } else if (response.status === 403) {
        setIsAuthorized(false);
      } else {
        console.error('Error fetching data:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCreateUser = async () => {
    try {
      const values = await form.validateFields();
      const response = await fetch(`${API_URL}/signup/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values)
      });
      if (response.ok) {
        const result = await response.json();
        console.log('User created successfully:', result);
        // Refetch the data to update the table
        fetchData();
        // Reset the form
        form.resetFields();
      } else {
        console.error('Error creating user:', response.statusText);
      }
    } catch (error) {
      console.error('Error creating user:', error);
    }
  };

  const handleDeleteUser = async (id) => {
    const accessToken = sessionStorage.getItem('accessToken');
    try {
      const response = await fetch(`${API_URL}/delete-user/${id}/`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });
      if (response.ok) {
        console.log('User deleted successfully');
        // Refetch the data to update the table
        fetchData();
      } else if (response.status === 403) {
        message.error('This user cannot be deleted');
      } else {
        console.error('Error deleting user:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Droits',
      dataIndex: 'is_superuser',
      key: 'is_superuser',
      render: (isSuperuser) => (
        <Tag color={isSuperuser ? 'green' : 'volcano'}>
          {isSuperuser ? 'Superuser' : 'User'}
        </Tag>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Popconfirm
          title="Etes vous sure de terminer l'accés pour cette utilisateur ?"
          onConfirm={() => handleDeleteUser(record.id)}
          okText="Oui"
          cancelText="Non"
        >
          <Button type="link" icon={<DeleteOutlined />} />
        </Popconfirm>
      ),
    },
  ];

  return (
    <div style={{
      padding: 64,
    }}>
      {isAuthorized ? (
        <>
          <Form form={form} layout="inline" onFinish={handleCreateUser}>
            <Form.Item
              name="email"
              label="Email"
              rules={[{ required: true, message: 'Please input the email!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="username"
              label="Username"
              rules={[{ required: true, message: 'Please input the username!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="password"
              label="Password"
              rules={[{ required: true, message: 'Please input the password!' }]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Create
              </Button>
            </Form.Item>
          </Form>
          <Table dataSource={data} columns={columns} loading={loading} rowKey="id" />
        </>
      ) : (
        <Result
          status="error"
          title="Vous avez pas le droit de voir les utilisateurs"
          subTitle="Contactez votre administrateur pour plus d'informations"
        />
      )}
    </div>
  );
};

export default App;
