import React from 'react';
import { Text, Box } from "@radix-ui/themes";
import { Select, MenuItem, FormControl } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    maxWidth:240,
    minWidth: 240,
    marginTop: '12px',
    marginBottom: '12px',
  },
  select: {
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },
  menuItem: {
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },
}));

const JobType = ({ selectedValue2, value, setfunction }) => {
  const classes = useStyles();

  const handleChange = (event) => {
    setfunction(event.target.value);
  };

  const renderMenuItems = () => {
    const items = [];

    if (selectedValue2 === "0") {
      items.push(
        <MenuItem value="Adjoints administratifs/adjointes..." className={classes.menuItem} key="Adjoints administratifs/adjointes...">Adjoints administratifs/adjointes administratives de bureau - général, juridique et médical</MenuItem>,
        <MenuItem value="Commis de bibliothèque..." className={classes.menuItem} key="Commis de bibliothèque...">Commis de bibliothèque, de correspondance et autres commis</MenuItem>,
        <MenuItem value="Commis en bureautique et en éditique" className={classes.menuItem} key="Commis en bureautique et en éditique">Commis en bureautique et en éditique</MenuItem>,
        <MenuItem value="Finance, assurance..." className={classes.menuItem} key="Finance, assurance...">Finance, assurance et personnel de soutien administratif</MenuItem>,
        <MenuItem value="Personnel administratif..." className={classes.menuItem} key="Personnel administratif...">Personnel administratif et de réglementation</MenuItem>,
        <MenuItem value="Personnel au courrier..." className={classes.menuItem} key="Personnel au courrier...">Personnel au courrier et à la distribution de messages</MenuItem>,
        <MenuItem value="Personnel au travail..." className={classes.menuItem} key="Personnel au travail...">Personnel au travail général de bureau</MenuItem>,
        <MenuItem value="Personnel en finance, assurance..." className={classes.menuItem} key="Personnel en finance, assurance...">Personnel en finance, assurance et personnel assimilé en administration des affaires, assurance et personnel assimilé en administration des affaires</MenuItem>,
        <MenuItem value="Personnel en logistiques..." className={classes.menuItem} key="Personnel en logistiques...">Personnel en logistiques de chaîne d'approvisionnement, en suivi et en coordination d'horaires</MenuItem>,
        <MenuItem value="Professionnels/professionnelles en finance..." className={classes.menuItem} key="Professionnels/professionnelles en finance...">Professionnels/professionnelles en finance, en vérification et en comptabilité</MenuItem>,
        <MenuItem value="Professionnels/professionnelles en gestion..." className={classes.menuItem} key="Professionnels/professionnelles en gestion...">Professionnels/professionnelles en gestion des ressources humaines et en services aux entreprises</MenuItem>,
        <MenuItem value="Sténographes judiciaires..." className={classes.menuItem} key="Sténographes judiciaires...">Sténographes judiciaires, transcripteurs/transcriptrices, techniciens/techniciennes à la gestion des documents et agents/agentes de statistiques</MenuItem>,
        <MenuItem value="Superviseurs/superviseures..." className={classes.menuItem} key="Superviseurs/superviseures...">Superviseurs/superviseures de services administratifs</MenuItem>
      );
    } else if (selectedValue2 === "1") {
      items.push(
        <MenuItem value="Annonceurs/annonceures..." className={classes.menuItem} key="Annonceurs/annonceures...">Annonceurs/annonceures et autres artistes de spectacle</MenuItem>,
        <MenuItem value="Athlètes, entraîneurs..." className={classes.menuItem} key="Athlètes, entraîneurs...">Athlètes, entraîneurs/entraîneuses, arbitres et personnel assimilé des sports et des loisirs</MenuItem>,
        <MenuItem value="Concepteurs/conceptrices..." className={classes.menuItem} key="Concepteurs/conceptrices...">Concepteurs/conceptrices artistiques et artisans/artisanes</MenuItem>,
        <MenuItem value="Personnel technique..." className={classes.menuItem} key="Personnel technique...">Personnel technique des bibliothèques, des archives publiques, des musées et des galeries d'art</MenuItem>,
        <MenuItem value="Photographes, techniciens..." className={classes.menuItem} key="Photographes, techniciens...">Photographes, techniciens/techniciennes en graphisme, personnel technique et personnel de coordination du cinéma, de la radiotélédiffusion et des arts de la scène</MenuItem>,
        <MenuItem value="Professionnels/professionnelles de la rédaction..." className={classes.menuItem} key="Professionnels/professionnelles de la rédaction...">Professionnels/professionnelles de la rédaction, de la traduction et personnel professionnel assimilé des communications</MenuItem>,
        <MenuItem value="Professionnels/professionnelles des arts plastiques..." className={classes.menuItem} key="Professionnels/professionnelles des arts plastiques...">Professionnels/professionnelles des arts plastiques et des arts de la scène</MenuItem>,
        <MenuItem value="Professionnels/professionnelles des bibliothèques..." className={classes.menuItem} key="Professionnels/professionnelles des bibliothèques...">Professionnels/professionnelles des bibliothèques, des archives, des musées et des galeries d'art</MenuItem>
      );
    } else if (selectedValue2 === "3") {
      items.push(
        <MenuItem value="Agents/agentes de sécurité..." className={classes.menuItem} key="Agents/agentes de sécurité...">Agents/agentes de sécurité et personnel assimilé des services de sécurité</MenuItem>,
        <MenuItem value="Autre personnel de soutien..." className={classes.menuItem} key="Autre personnel de soutien...">Autre personnel de soutien aux ventes et personnel assimilé</MenuItem>,
        <MenuItem value="Autre personnel des services..." className={classes.menuItem} key="Autre personnel des services...">Autre personnel des services personnels</MenuItem>,
        <MenuItem value="Autres services de soutien..." className={classes.menuItem} key="Autres services de soutien...">Autres services de soutien et personnel assimilé</MenuItem>,
        <MenuItem value="Bouchers/bouchères..." className={classes.menuItem} key="Bouchers/bouchères...">Bouchers/bouchères et boulangers-pâtissiers/boulangères-pâtissières</MenuItem>,
        <MenuItem value="Caissiers/caissières" className={classes.menuItem} key="Caissiers/caissières">Caissiers/caissières</MenuItem>,
        <MenuItem value="Chefs et cuisiniers/cuisinières" className={classes.menuItem} key="Chefs et cuisiniers/cuisinières">Chefs et cuisiniers/cuisinières</MenuItem>,
        <MenuItem value="Nettoyeurs/nettoyeuses" className={classes.menuItem} key="Nettoyeurs/nettoyeuses">Nettoyeurs/nettoyeuses</MenuItem>,
        <MenuItem value="Personnel de l'assurance..." className={classes.menuItem} key="Personnel de l'assurance...">Personnel de l'assurance, de l'immobilier et des ventes financières</MenuItem>,
        <MenuItem value="Personnel de l'hébergement..." className={classes.menuItem} key="Personnel de l'hébergement...">Personnel de l'hébergement et des voyages</MenuItem>,
        <MenuItem value="Personnel de soutien en services" className={classes.menuItem} key="Personnel de soutien en services">Personnel de soutien en services d'hébergement, de voyage et de loisirs</MenuItem>,
        <MenuItem value="Personnel des services de tourisme..." className={classes.menuItem} key="Personnel des services de tourisme...">Personnel des services de tourisme et de loisirs</MenuItem>,
        <MenuItem value="Personnel des services des aliments" className={classes.menuItem} key="Personnel des services des aliments">Personnel des services des aliments et des boissons</MenuItem>,
        <MenuItem value="Personnel spécialisé en services..." className={classes.menuItem} key="Personnel spécialisé en services...">Personnel spécialisé en services personnels et en service à la clientèle</MenuItem>,
        <MenuItem value="Personnel technique spécialisé..." className={classes.menuItem} key="Personnel technique spécialisé...">Personnel technique spécialisé du commerce de gros et acheteurs/acheteuses du commerce de détail et de gros</MenuItem>,
        <MenuItem value="Représentants/représentantes des ventes..." className={classes.menuItem} key="Représentants/représentantes des ventes...">Représentants/représentantes des ventes et des comptes- commerce de gros</MenuItem>,
        <MenuItem value="Représentants/représentantes du service..." className={classes.menuItem} key="Représentants/représentantes du service...">Représentants/représentantes du service à la clientèle et de l'information</MenuItem>,
        <MenuItem value="Serveurs/serveuses au comptoir..." className={classes.menuItem} key="Serveurs/serveuses au comptoir...">Serveurs/serveuses au comptoir, aides de cuisine et personnel de soutien assimilé</MenuItem>,
        <MenuItem value="Superviseurs/superviseures de services" className={classes.menuItem} key="Superviseurs/superviseures de services">Superviseurs/superviseures de services</MenuItem>,
        <MenuItem value="Superviseurs/superviseures des ventes..." className={classes.menuItem} key="Superviseurs/superviseures des ventes...">Superviseurs/superviseures des ventes - commerce de détail</MenuItem>,
        <MenuItem value="Vendeurs/vendeuses..." className={classes.menuItem} key="Vendeurs/vendeuses...">Vendeurs/vendeuses - commerce de détail</MenuItem>
      );
    } else if (selectedValue2 === "4") {
      items.push(
        <MenuItem value="Dispensateurs/dispensatrices..." className={classes.menuItem} key="Dispensateurs/dispensatrices...">Dispensateurs/dispensatrices de soins en milieu familial et personnel de soutien en enseignement</MenuItem>,
        <MenuItem value="Enseignants/enseignantes au niveau collégial..." className={classes.menuItem} key="Enseignants/enseignantes au niveau collégial...">Enseignants/enseignantes au niveau collégial et autres instructeurs/instructrices en formation professionnelle</MenuItem>,
        <MenuItem value="Enseignants/enseignantes aux niveaux secondaire..." className={classes.menuItem} key="Enseignants/enseignantes aux niveaux secondaire...">Enseignants/enseignantes aux niveaux secondaire, primaire et préscolaire et conseillers/conseillères d'orientation</MenuItem>,
        <MenuItem value="Juges, avocats..." className={classes.menuItem} key="Juges, avocats...">Juges, avocats/avocates et notaires</MenuItem>,
        <MenuItem value="Personnel des services de protection..." className={classes.menuItem} key="Personnel des services de protection...">Personnel des services de protection public de première ligne</MenuItem>,
        <MenuItem value="Personnel juridique et de soutien" className={classes.menuItem} key="Personnel juridique et de soutien">Personnel juridique et de soutien des services de protection public</MenuItem>,
        <MenuItem value="Personnel paraprofessionnel des services..." className={classes.menuItem} key="Personnel paraprofessionnel des services...">Personnel paraprofessionnel des services juridiques, sociaux, communautaires et de l'enseignement</MenuItem>,
        <MenuItem value="Professeurs/professeures d'université..." className={classes.menuItem} key="Professeurs/professeures d'université...">Professeurs/professeures d'université et assistants/assistantes d'enseignement au niveau postsecondaire</MenuItem>,
        <MenuItem value="Professionnels/professionnelles des services..." className={classes.menuItem} key="Professionnels/professionnelles des services...">Professionnels/professionnelles des services sociaux et communautaires</MenuItem>,
        <MenuItem value="Recherchistes, experts-conseils..." className={classes.menuItem} key="Recherchistes, experts-conseils...">Recherchistes, experts-conseils/expertes-conseils et agents/agentes des politiques et des programmes</MenuItem>
      );
    } else if (selectedValue2 === "6") {
      items.push(
        <MenuItem value="Autre personnel de montage..." className={classes.menuItem} key="Autre personnel de montage...">Autre personnel de montage et personnel assimilé</MenuItem>,
        <MenuItem value="Manoeuvres dans la transformation..." className={classes.menuItem} key="Manoeuvres dans la transformation...">Manoeuvres dans la transformation, la fabrication et les services d'utilité publique</MenuItem>,
        <MenuItem value="Monteurs/monteuses..." className={classes.menuItem} key="Monteurs/monteuses...">Monteurs/monteuses de matériel mécanique, électrique et électronique</MenuItem>,
        <MenuItem value="Opérateurs/opératrices de machines dans la production..." className={classes.menuItem} key="Opérateurs/opératrices de machines dans la production...">Opérateurs/opératrices de machines dans la production des pâtes et papiers et dans la transformation et la fabrication du bois et personnel assimilé</MenuItem>,
        <MenuItem value="Opérateurs/opératrices de machines dans la transformation..." className={classes.menuItem} key="Opérateurs/opératrices de machines dans la transformation...">Opérateurs/opératrices de machines dans la transformation et la confection de produits textiles, d'articles en tissu, en fourrure et en cuir et personnel assimilé</MenuItem>,
        <MenuItem value="Opérateurs/opératrices de machines dans le traitement de produits..." className={classes.menuItem} key="Opérateurs/opératrices de machines dans le traitement de produits...">Opérateurs/opératrices de machines dans le traitement de produits chimiques, du caoutchouc et du plastique et personnel assimilé</MenuItem>,
        <MenuItem value="Opérateurs/opératrices de machines dans le traitement et la fabrication..." className={classes.menuItem} key="Opérateurs/opératrices de machines dans le traitement et la fabrication...">Opérateurs/opératrices de machines dans le traitement et la fabrication des métaux et des minerais et personnel assimilé</MenuItem>,
        <MenuItem value="Opérateurs/opératrices de machines et personnel assimilé..." className={classes.menuItem} key="Opérateurs/opératrices de machines et personnel assimilé...">Opérateurs/opératrices de machines et personnel assimilé dans la transformation des aliments et boissons et produits connexes</MenuItem>,
        <MenuItem value="Opérateurs/opératrices de poste..." className={classes.menuItem} key="Opérateurs/opératrices de poste...">Opérateurs/opératrices de poste central de contrôle dans les procédés de transformation et de fabrication</MenuItem>,
        <MenuItem value="Opérateurs/opératrices d'équipement" className={classes.menuItem} key="Opérateurs/opératrices d'équipement">Opérateurs/opératrices d'équipement à imprimer et personnel assimilé</MenuItem>,
        <MenuItem value="Opérateurs/opératrices et contrôleurs" className={classes.menuItem} key="Opérateurs/opératrices et contrôleurs">Opérateurs/opératrices et contrôleurs/contrôleuses d'équipements d'utilités publiques</MenuItem>,
        <MenuItem value="Surveillants/surveillantes dans la fabrication..." className={classes.menuItem} key="Surveillants/surveillantes dans la fabrication...">Surveillants/surveillantes dans la fabrication et le montage</MenuItem>,
        <MenuItem value="Surveillants/surveillantes dans la transformation..." className={classes.menuItem} key="Surveillants/surveillantes dans la transformation...">Surveillants/surveillantes dans la transformation et la fabrication</MenuItem>
      );
    } else if (selectedValue2 === "7") {
      items.push(
        <MenuItem value="Aumônier" className={classes.menuItem} key="Aumônier">Aumônier</MenuItem>,
        <MenuItem value="Avocat/Avocate" className={classes.menuItem} key="Avocat/Avocate">Avocat/Avocate</MenuItem>,
        <MenuItem value="Chercheur/Chercheuse en communications" className={classes.menuItem} key="Chercheur/Chercheuse en communications">Chercheur/Chercheuse en communications</MenuItem>,
        <MenuItem value="Commis de soutien à la gestion des ressources, Réserve aérienne" className={classes.menuItem} key="Commis de soutien à la gestion des ressources, Réserve aérienne">Commis de soutien à la gestion des ressources, Réserve aérienne</MenuItem>,
        <MenuItem value="Commis de soutien à la gestion des ressources, Réserve de l'Armée de terre" className={classes.menuItem} key="Commis de soutien à la gestion des ressources, Réserve de l'Armée de terre">Commis de soutien à la gestion des ressources, Réserve de l'Armée de terre</MenuItem>,
        <MenuItem value="Commis de soutien à la gestion des ressources, Réserve navale" className={classes.menuItem} key="Commis de soutien à la gestion des ressources, Réserve navale">Commis de soutien à la gestion des ressources, Réserve navale</MenuItem>,
        <MenuItem value="Communicateur naval/Communicatrice navale" className={classes.menuItem} key="Communicateur naval/Communicatrice navale">Communicateur naval/Communicatrice navale</MenuItem>,
        <MenuItem value="Conducteur/Conductrice..." className={classes.menuItem} key="Conducteur/Conductrice...">Conducteur/Conductrice de matériel mobile de soutien</MenuItem>,
        <MenuItem value="Cuisinier/Cuisinière" className={classes.menuItem} key="Cuisinier/Cuisinière">Cuisinier/Cuisinière</MenuItem>,
        <MenuItem value="Dentiste" className={classes.menuItem} key="Dentiste">Dentiste</MenuItem>,
        <MenuItem value="Infirmier/Infirmière" className={classes.menuItem} key="Infirmier/Infirmière">Infirmier/Infirmière</MenuItem>,
        <MenuItem value="Logistique (soutien), Officier, Réserve navale" className={classes.menuItem} key="Logistique (soutien), Officier, Réserve navale">Logistique (soutien), Officier, Réserve navale</MenuItem>,
        <MenuItem value="Manoeuvrier" className={classes.menuItem} key="Manoeuvrier">Manoeuvrier</MenuItem>,
        <MenuItem value="Médecin" className={classes.menuItem} key="Médecin">Médecin</MenuItem>,
        <MenuItem value="Musicien/Musicienne" className={classes.menuItem} key="Musicien/Musicienne">Musicien/Musicienne</MenuItem>,
        <MenuItem value="Officier d'administration des services de santé" className={classes.menuItem} key="Officier d'administration des services de santé">Officier d'administration des services de santé</MenuItem>,
        <MenuItem value="Officier d'artillerie" className={classes.menuItem} key="Officier d'artillerie">Officier d'artillerie</MenuItem>,
        <MenuItem value="Officier de la logistique" className={classes.menuItem} key="Officier de la logistique">Officier de la logistique</MenuItem>,
        <MenuItem value="Officier de la police militaire" className={classes.menuItem} key="Officier de la police militaire">Officier de la police militaire</MenuItem>,
        <MenuItem value="Officier de la sélection du personnel" className={classes.menuItem} key="Officier de la sélection du personnel">Officier de la sélection du personnel</MenuItem>,
        <MenuItem value="Officier des affaires publiques" className={classes.menuItem} key="Officier des affaires publiques">Officier des affaires publiques</MenuItem>,
        <MenuItem value="Officier des blindés" className={classes.menuItem} key="Officier des blindés">Officier des blindés</MenuItem>,
        <MenuItem value="Officier des opérations maritimes..." className={classes.menuItem} key="Officier des opérations maritimes...">Officier des opérations maritimes de surface et sous-marines</MenuItem>,
        <MenuItem value="Officier des systèmes de combat aérien" className={classes.menuItem} key="Officier des systèmes de combat aérien">Officier des systèmes de combat aérien</MenuItem>,
        <MenuItem value="Officier des transmissions" className={classes.menuItem} key="Officier des transmissions">Officier des transmissions</MenuItem>,
        <MenuItem value="Officier d'infanterie" className={classes.menuItem} key="Officier d'infanterie">Officier d'infanterie</MenuItem>,
        <MenuItem value="Officier du contrôle aérospatial" className={classes.menuItem} key="Officier du contrôle aérospatial">Officier du contrôle aérospatial</MenuItem>,
        <MenuItem value="Officier du génie" className={classes.menuItem} key="Officier du génie">Officier du génie</MenuItem>,
        <MenuItem value="Officier du génie aérospatial" className={classes.menuItem} key="Officier du génie aérospatial">Officier du génie aérospatial</MenuItem>,
        <MenuItem value="Officier du génie des communications..." className={classes.menuItem} key="Officier du génie des communications...">Officier du génie des communications et électronique (Aérien)</MenuItem>,
        <MenuItem value="Officier du génie électrique..." className={classes.menuItem} key="Officier du génie électrique...">Officier du génie électrique et mécanique</MenuItem>,
        <MenuItem value="Officier du renseignement..." className={classes.menuItem} key="Officier du renseignement...">Officier du renseignement, Réserve navale</MenuItem>,
        <MenuItem value="Opérateur/Opératrice de contrôle" className={classes.menuItem} key="Opérateur/Opératrice de contrôle">Opérateur/Opératrice de contrôle aérospatial</MenuItem>,
        <MenuItem value="Opérateur/Opératrice d'équipement" className={classes.menuItem} key="Opérateur/Opératrice d'équipement">Opérateur/Opératrice d'équipement d'information de combat (Marine)</MenuItem>,
        <MenuItem value="Opérateur/Opératrice des transmissions" className={classes.menuItem} key="Opérateur/Opératrice des transmissions">Opérateur/Opératrice des transmissions</MenuItem>,
        <MenuItem value="Pharmacien/Pharmacienne" className={classes.menuItem} key="Pharmacien/Pharmacienne">Pharmacien/Pharmacienne</MenuItem>,
        <MenuItem value="Pilote" className={classes.menuItem} key="Pilote">Pilote</MenuItem>,
        <MenuItem value="Plongeur d'inspection portuaire" className={classes.menuItem} key="Plongeur d'inspection portuaire">Plongeur d'inspection portuaire</MenuItem>,
        <MenuItem value="Police militaire" className={classes.menuItem} key="Police militaire">Police militaire</MenuItem>,
        <MenuItem value="Pompier/Pompière" className={classes.menuItem} key="Pompier/Pompière">Pompier/Pompière</MenuItem>,
        <MenuItem value="Poseur/Poseuse de lignes" className={classes.menuItem} key="Poseur/Poseuse de lignes">Poseur/Poseuse de lignes</MenuItem>,
        <MenuItem value="Sapeur/Sapeuse de combat" className={classes.menuItem} key="Sapeur/Sapeuse de combat">Sapeur/Sapeuse de combat</MenuItem>,
        <MenuItem value="Soldat d'infanterie" className={classes.menuItem} key="Soldat d'infanterie">Soldat d'infanterie</MenuItem>,
        <MenuItem value="Soldat de l'artillerie (défense aérienne)" className={classes.menuItem} key="Soldat de l'artillerie (défense aérienne)">Soldat de l'artillerie (défense aérienne)</MenuItem>,
        <MenuItem value="Soldat de l'artillerie (campagne)" className={classes.menuItem} key="Soldat de l'artillerie (campagne)">Soldat de l'artillerie (campagne)</MenuItem>,
        <MenuItem value="Soldat des blindés" className={classes.menuItem} key="Soldat des blindés">Soldat des blindés</MenuItem>,
        <MenuItem value="Spécialiste du renseignement" className={classes.menuItem} key="Spécialiste du renseignement">Spécialiste du renseignement</MenuItem>,
        <MenuItem value="Technicien médical/Technicienne médicale" className={classes.menuItem} key="Technicien médical/Technicienne médicale">Technicien médical/Technicienne médicale</MenuItem>,
        <MenuItem value="Technicien/Technicienne d'armement / Terre" className={classes.menuItem} key="Technicien/Technicienne d'armement / Terre">Technicien/Technicienne d'armement / Terre</MenuItem>,
        <MenuItem value="Technicien/Technicienne de la construction" className={classes.menuItem} key="Technicien/Technicienne de la construction">Technicien/Technicienne de la construction</MenuItem>,
        <MenuItem value="Technicien/Technicienne de systèmes" className={classes.menuItem} key="Technicien/Technicienne de systèmes">Technicien/Technicienne de systèmes d'informations et télécommunications aérospatiales</MenuItem>,
        <MenuItem value="Technicien/Technicienne de véhicules, Réserve de l'armée" className={classes.menuItem} key="Technicien/Technicienne de véhicules, Réserve de l'armée">Technicien/Technicienne de véhicules, Réserve de l'armée</MenuItem>,
        <MenuItem value="Technicien/Technicienne des mouvements" className={classes.menuItem} key="Technicien/Technicienne des mouvements">Technicien/Technicienne des mouvements</MenuItem>,
        <MenuItem value="Technicien/Technicienne en approvisionnement, Réserve aérienne" className={classes.menuItem} key="Technicien/Technicienne en approvisionnement, Réserve aérienne">Technicien/Technicienne en approvisionnement, Réserve aérienne</MenuItem>,
        <MenuItem value="Technicien/Technicienne en approvisionnement, Réserve de l'Armée de terre" className={classes.menuItem} key="Technicien/Technicienne en approvisionnement, Réserve de l'Armée de terre">Technicien/Technicienne en approvisionnement, Réserve de l'Armée de terre</MenuItem>,
        <MenuItem value="Technicien/Technicienne en approvisionnement, Réserve navale" className={classes.menuItem} key="Technicien/Technicienne en approvisionnement, Réserve navale">Technicien/Technicienne en approvisionnement, Réserve navale</MenuItem>,
        <MenuItem value="Technicien/Technicienne en distribution électrique" className={classes.menuItem} key="Technicien/Technicienne en distribution électrique">Technicien/Technicienne en distribution électrique</MenuItem>,
        <MenuItem value="Technicien/Technicienne en eau, produits..." className={classes.menuItem} key="Technicien/Technicienne en eau, produits...">Technicien/Technicienne en eau, produits pétroliers et environnement</MenuItem>,
        <MenuItem value="Technicien/Technicienne en géomatique" className={classes.menuItem} key="Technicien/Technicienne en géomatique">Technicien/Technicienne en géomatique</MenuItem>,
        <MenuItem value="Technicien/Technicienne en groupes électrogènes" className={classes.menuItem} key="Technicien/Technicienne en groupes électrogènes">Technicien/Technicienne en groupes électrogènes</MenuItem>,
        <MenuItem value="Technicien/Technicienne en imagerie" className={classes.menuItem} key="Technicien/Technicienne en imagerie">Technicien/Technicienne en imagerie</MenuItem>,
        <MenuItem value="Technicien/Technicienne en plomberie..." className={classes.menuItem} key="Technicien/Technicienne en plomberie...">Technicien/Technicienne en plomberie et chauffage</MenuItem>,
        <MenuItem value="Technicien/Technicienne en réfrigération..." className={classes.menuItem} key="Technicien/Technicienne en réfrigération...">Technicien/Technicienne en réfrigération et mécanique</MenuItem>,
        <MenuItem value="Technicien/Technicienne en structures..." className={classes.menuItem} key="Technicien/Technicienne en structures...">Technicien/Technicienne en structures d'aéronefs</MenuItem>,
        <MenuItem value="Technicien/Technicienne en systèmes aéronautiques" className={classes.menuItem} key="Technicien/Technicienne en systèmes aéronautiques">Technicien/Technicienne en systèmes aéronautiques</MenuItem>,
        <MenuItem value="Technicien/Technicienne en systèmes avioniques" className={classes.menuItem} key="Technicien/Technicienne en systèmes avioniques">Technicien/Technicienne en systèmes avioniques</MenuItem>,
        <MenuItem value="Technicien/Technicienne systèmes d'informations" className={classes.menuItem} key="Technicien/Technicienne systèmes d'informations">Technicien/Technicienne systèmes d'informations et communications</MenuItem>,
        <MenuItem value="Technologue en électronique biomédicale" className={classes.menuItem} key="Technologue en électronique biomédicale">Technologue en électronique biomédicale</MenuItem>
      );
    } else if (selectedValue2 === "8") {
      items.push(
        <MenuItem value="Directeurs/directrices - commerce..." className={classes.menuItem} key="Directeurs/directrices - commerce...">Directeurs/directrices - commerce de gros et de détail</MenuItem>,
        <MenuItem value="Directeurs/directrices de la construction...." className={classes.menuItem} key="Directeurs/directrices de la construction....">Directeurs/directrices de la construction, de l'exploitation et de l'entretien d'immeubles</MenuItem>,
        <MenuItem value="Directeurs/directrices de la fabrication..." className={classes.menuItem} key="Directeurs/directrices de la fabrication...">Directeurs/directrices de la fabrication et des services d'utilité publique</MenuItem>,
        <MenuItem value="Directeurs/directrices de la fonction..." className={classes.menuItem} key="Directeurs/directrices de la fonction...">Directeurs/directrices de la fonction publique</MenuItem>,
        <MenuItem value="Directeurs/directrices de la restauration..." className={classes.menuItem} key="Directeurs/directrices de la restauration...">Directeurs/directrices de la restauration et des services d'hébergement</MenuItem>,
        <MenuItem value="Directeurs/directrices de l'enseignement..." className={classes.menuItem} key="Directeurs/directrices de l'enseignement...">Directeurs/directrices de l'enseignement et des services sociaux et communautaires</MenuItem>,
        <MenuItem value="Directeurs/directrices de l'exploitation..." className={classes.menuItem} key="Directeurs/directrices de l'exploitation...">Directeurs/directrices de l'exploitation des ressources naturelles et de la pêche</MenuItem>,
        <MenuItem value="Directeurs/directrices des arts..." className={classes.menuItem} key="Directeurs/directrices des arts...">Directeurs/directrices des arts, de la culture, des sports et des loisirs</MenuItem>,
        <MenuItem value="Directeurs/directrices des services administratifs" className={classes.menuItem} key="Directeurs/directrices des services administratifs">Directeurs/directrices des services administratifs</MenuItem>,
        <MenuItem value="Directeurs/directrices des services de communications" className={classes.menuItem} key="Directeurs/directrices des services de communications">Directeurs/directrices des services de communications</MenuItem>,
        <MenuItem value="Directeurs/directrices des services de génie..." className={classes.menuItem} key="Directeurs/directrices des services de génie...">Directeurs/directrices des services de génie, d'architecture, de sciences et des systèmes informatiques</MenuItem>,
        <MenuItem value="Directeurs/directrices des services de la protection..." className={classes.menuItem} key="Directeurs/directrices des services de la protection...">Directeurs/directrices des services de la protection du public</MenuItem>,
        <MenuItem value="Directeurs/directrices des services financiers..." className={classes.menuItem} key="Directeurs/directrices des services financiers...">Directeurs/directrices des services financiers et des services aux entreprises</MenuItem>,
        <MenuItem value="Directeurs/directrices des soins de santé..." className={classes.menuItem} key="Directeurs/directrices des soins de santé...">Directeurs/directrices des soins de santé</MenuItem>,
        <MenuItem value="Directeurs/directrices des transports..." className={classes.menuItem} key="Directeurs/directrices des transports...">Directeurs/directrices des transports</MenuItem>,
        <MenuItem value="Directeurs/directrices des ventes corporatives" className={classes.menuItem} key="Directeurs/directrices des ventes corporatives">Directeurs/directrices des ventes corporatives</MenuItem>,
        <MenuItem value="Directeurs/directrices du service à la clientèle..." className={classes.menuItem} key="Directeurs/directrices du service à la clientèle...">Directeurs/directrices du service à la clientèle et des services personnels</MenuItem>,
        <MenuItem value="Gestionnaires en agriculture..." className={classes.menuItem} key="Gestionnaires en agriculture...">Gestionnaires en agriculture, en horticulture et en aquaculture</MenuItem>,
        <MenuItem value="Membres des corps législatifs..." className={classes.menuItem} key="Membres des corps législatifs...">Membres des corps législatifs et cadres supérieurs/cadres supérieures, y compris les représentants/représentantes politiques</MenuItem>
      );
    } else if (selectedValue2 === "9") {
      items.push(
        <MenuItem value="Aides de soutien..." className={classes.menuItem} key="Aides de soutien...">Aides de soutien des métiers et manoeuvres</MenuItem>,
        <MenuItem value="Autre personnel des métiers de la construction..." className={classes.menuItem} key="Autre personnel des métiers de la construction...">Autre personnel des métiers de la construction</MenuItem>,
        <MenuItem value="Autres conducteurs/conductrices..." className={classes.menuItem} key="Autres conducteurs/conductrices...">Autres conducteurs/conductrices de matériel de transport et personnel d'entretien assimilé</MenuItem>,
        <MenuItem value="Autres mécaniciens/mécaniciennes..." className={classes.menuItem} key="Autres mécaniciens/mécaniciennes...">Autres mécaniciens/mécaniciennes et réparateurs/réparatrices connexes</MenuItem>,
        <MenuItem value="Charpentiers/charpentières..." className={classes.menuItem} key="Charpentiers/charpentières...">Charpentiers/charpentières et ébénistes</MenuItem>,
        <MenuItem value="Conducteurs/conductrices de véhicules" className={classes.menuItem} key="Conducteurs/conductrices de véhicules">Conducteurs/conductrices de véhicules automobiles et de véhicules de transport en commun</MenuItem>,
        <MenuItem value="Conducteurs/conductrices d'équipement lourd" className={classes.menuItem} key="Conducteurs/conductrices d'équipement lourd">Conducteurs/conductrices d'équipement lourd</MenuItem>,
        <MenuItem value="Débardeurs/débardeuses..." className={classes.menuItem} key="Débardeurs/débardeuses...">Débardeurs/débardeuses et manutentionnaires</MenuItem>,
        <MenuItem value="Électriciens/électriciennes..." className={classes.menuItem} key="Électriciens/électriciennes...">Électriciens/électriciennes et monteurs/monteuses de lignes d'électricité et de télécommunications</MenuItem>,
        <MenuItem value="Entrepreneurs/entrepreneuses et contremaîtres/ contremaîtresses du personnel des métiers d'entretien..." className={classes.menuItem} key="Entrepreneurs/entrepreneuses et contremaîtres/ contremaîtresses du personnel des métiers d'entretien...">Entrepreneurs/entrepreneuses et contremaîtres/ contremaîtresses du personnel des métiers d'entretien et des opérateurs d'équipement lourd et de transport</MenuItem>,
        <MenuItem value="Entrepreneurs/entrepreneuses et contremaîtres/ contremaîtresses du personnel des métiers industriels..." className={classes.menuItem} key="Entrepreneurs/entrepreneuses et contremaîtres/ contremaîtresses du personnel des métiers industriels...">Entrepreneurs/entrepreneuses et contremaîtres/ contremaîtresses du personnel des métiers industriels, de la construction, de l'électricité et du personnel assimilé</MenuItem>,
        <MenuItem value="Grutiers/grutières..." className={classes.menuItem} key="Grutiers/grutières...">Grutiers/grutières, foreurs/foreuses et dynamiteurs/dynamiteuses</MenuItem>,
        <MenuItem value="Manoeuvres aux travaux..." className={classes.menuItem} key="Manoeuvres aux travaux...">Manoeuvres aux travaux publics et personnel assimilé</MenuItem>,
        <MenuItem value="Mécaniciens/mécaniciennes de machinerie..." className={classes.menuItem} key="Mécaniciens/mécaniciennes de machinerie...">Mécaniciens/mécaniciennes de machinerie et d'équipement de transport</MenuItem>,
        <MenuItem value="Mécaniciens/mécaniciennes de véhicules..." className={classes.menuItem} key="Mécaniciens/mécaniciennes de véhicules...">Mécaniciens/mécaniciennes de véhicules automobiles</MenuItem>,
        <MenuItem value="Personnel de l'imprimerie..." className={classes.menuItem} key="Personnel de l'imprimerie...">Personnel de l'imprimerie, autre personnel des métiers et personnel assimilé</MenuItem>,
        <MenuItem value="Personnel de maçonnerie..." className={classes.menuItem} key="Personnel de maçonnerie...">Personnel de maçonnerie et de plâtrage</MenuItem>,
        <MenuItem value="Personnel des métiers d'usinage..." className={classes.menuItem} key="Personnel des métiers d'usinage...">Personnel des métiers d'usinage, du formage, du profilage et du montage du métal</MenuItem>,
        <MenuItem value="Personnel des opérations..." className={classes.menuItem} key="Personnel des opérations...">Personnel des opérations du transport ferroviaire</MenuItem>,
        <MenuItem value="Personnel d'installation..." className={classes.menuItem} key="Personnel d'installation...">Personnel d'installation, de réparation et d'entretien</MenuItem>,
        <MenuItem value="Plombiers/plombières..." className={classes.menuItem} key="Plombiers/plombières...">Plombiers/plombières, tuyauteurs/tuyauteuses et monteurs/monteuses d'installations au gaz</MenuItem>
      );
    } else if (selectedValue2 === "11") {
      items.push(
        <MenuItem value="Autre personnel de la pêche..." className={classes.menuItem} key="Autre personnel de la pêche...">Autre personnel de la pêche et personnel du trappage et de la chasse</MenuItem>,
        <MenuItem value="Capitaines de bateaux..." className={classes.menuItem} key="Capitaines de bateaux...">Capitaines de bateaux de pêche et pêcheurs/pêcheuses</MenuItem>,
        <MenuItem value="Conducteurs/conductrices de machines..." className={classes.menuItem} key="Conducteurs/conductrices de machines...">Conducteurs/conductrices de machines d'abattage d'arbres</MenuItem>,
        <MenuItem value="Entrepreneurs/entrepreneuses et surveillants/surveillantes de l'exploitation..." className={classes.menuItem} key="Entrepreneurs/entrepreneuses et surveillants/surveillantes de l'exploitation...">Entrepreneurs/entrepreneuses et surveillants/surveillantes de l'exploitation des mines, du pétrole et du gaz</MenuItem>,
        <MenuItem value="Entrepreneurs/entrepreneuses et surveillants/surveillantes en agriculture..." className={classes.menuItem} key="Entrepreneurs/entrepreneuses et surveillants/surveillantes en agriculture...">Entrepreneurs/entrepreneuses et surveillants/surveillantes en agriculture, en horticulture et opérations et services connexes</MenuItem>,
        <MenuItem value="Manoeuvres à la récolte..." className={classes.menuItem} key="Manoeuvres à la récolte...">Manoeuvres à la récolte, en aménagement paysager et en ressources naturelles</MenuItem>,
        <MenuItem value="Personnel de l'exploitation..." className={classes.menuItem} key="Personnel de l'exploitation...">Personnel de l'exploitation forestière</MenuItem>,
        <MenuItem value="Personnel d'entretien des mines..." className={classes.menuItem} key="Personnel d'entretien des mines...">Personnel d'entretien des mines et du forage des puits de pétrole et de gaz</MenuItem>,
        <MenuItem value="Personnel du forage..." className={classes.menuItem} key="Personnel du forage...">Personnel du forage, des mines souterraines et de l'extraction de pétrole et de gaz, et autre personnel assimilé</MenuItem>,
        <MenuItem value="Personnel en agriculture..." className={classes.menuItem} key="Personnel en agriculture...">Personnel en agriculture et en horticulture</MenuItem>,
        <MenuItem value="Surveillants/surveillantes de l'exploitation forestière" className={classes.menuItem} key="Surveillants/surveillantes de l'exploitation forestière">Surveillants/surveillantes de l'exploitation forestière</MenuItem>
      );
    } else if (selectedValue2 === "13") {
      items.push(
        <MenuItem value="Autres contrôleurs..." className={classes.menuItem} key="Autres contrôleurs...">Autres contrôleurs/contrôleuses techniques et officiers/officières de réglementation</MenuItem>,
        <MenuItem value="Autres professionnels..." className={classes.menuItem} key="Autres professionnels...">Autres professionnels/ professionnelles en génie</MenuItem>,
        <MenuItem value="Mathématiciens/ mathématiciennes..." className={classes.menuItem} key="Mathématiciens/ mathématiciennes...">Mathématiciens/ mathématiciennes, statisticiens/statisticiennes et actuaires</MenuItem>,
        <MenuItem value="Officiers/officières et contrôleurs..." className={classes.menuItem} key="Officiers/officières et contrôleurs...">Officiers/officières et contrôleurs/contrôleuses des services de transport</MenuItem>,
        <MenuItem value="Personnel technique des sciences de la vie" className={classes.menuItem} key="Personnel technique des sciences de la vie">Personnel technique des sciences de la vie</MenuItem>,
        <MenuItem value="Personnel technique des sciences physiques" className={classes.menuItem} key="Personnel technique des sciences physiques">Personnel technique des sciences physiques</MenuItem>,
        <MenuItem value="Personnel technique en architecture" className={classes.menuItem} key="Personnel technique en architecture">Personnel technique en architecture, en dessin, en arpentage, en géomatique et en météorologie</MenuItem>,
        <MenuItem value="Personnel technique en génie civil..." className={classes.menuItem} key="Personnel technique en génie civil...">Personnel technique en génie civil, mécanique et industriel</MenuItem>,
        <MenuItem value="Personnel technique en génie électronique..." className={classes.menuItem} key="Personnel technique en génie électronique...">Personnel technique en génie électronique et électrique</MenuItem>,
        <MenuItem value="Personnel technique en informatique" className={classes.menuItem} key="Personnel technique en informatique">Personnel technique en informatique</MenuItem>,
        <MenuItem value="Professionnels/professionnelles des sciences de la vie" className={classes.menuItem} key="Professionnels/professionnelles des sciences de la vie">Professionnels/professionnelles des sciences de la vie</MenuItem>,
        <MenuItem value="Professionnels/professionnelles des sciences physiques" className={classes.menuItem} key="Professionnels/professionnelles des sciences physiques">Professionnels/professionnelles des sciences physiques</MenuItem>,
        <MenuItem value="Professionnels/professionnelles en architecture" className={classes.menuItem} key="Professionnels/professionnelles en architecture">Professionnels/professionnelles en architecture, en urbanisme et en arpentage</MenuItem>,
        <MenuItem value="Professionnels/professionnelles en génie civil" className={classes.menuItem} key="Professionnels/professionnelles en génie civil">Professionnels/professionnelles en génie civil, mécanique, électrique et chimique</MenuItem>,
        <MenuItem value="Professionnels/professionnelles en informatique" className={classes.menuItem} key="Professionnels/professionnelles en informatique">Professionnels/professionnelles en informatique</MenuItem>
      );
    } else if (selectedValue2 === "14") {
      items.push(
        <MenuItem value="Autre personnel technique" className={classes.menuItem} key="Autre personnel technique">Autre personnel technique en soins de santé</MenuItem>,
        <MenuItem value="Médecins, dentistes et vétérinaires" className={classes.menuItem} key="Médecins, dentistes et vétérinaires">Médecins, dentistes et vétérinaires</MenuItem>,
        <MenuItem value="Optométristes, chiropraticiens/ chiropraticiennes..." className={classes.menuItem} key="Optométristes, chiropraticiens/ chiropraticiennes...">Optométristes, chiropraticiens/ chiropraticiennes et autres professionnels/professionnelles en diagnostic et en traitement de la santé</MenuItem>,
        <MenuItem value="Personnel de soutien des services de santé" className={classes.menuItem} key="Personnel de soutien des services de santé">Personnel de soutien des services de santé</MenuItem>,
        <MenuItem value="Personnel professionnel en soins infirmiers" className={classes.menuItem} key="Personnel professionnel en soins infirmiers">Personnel professionnel en soins infirmiers</MenuItem>,
        <MenuItem value="Personnel technique en soins dentaires" className={classes.menuItem} key="Personnel technique en soins dentaires">Personnel technique en soins dentaires</MenuItem>,
        <MenuItem value="Pharmaciens/pharmaciennes, diététistes..." className={classes.menuItem} key="Pharmaciens/pharmaciennes, diététistes...">Pharmaciens/pharmaciennes, diététistes et nutritionnistes</MenuItem>,
        <MenuItem value="Professionnels/professionnelles en thérapie..." className={classes.menuItem} key="Professionnels/professionnelles en thérapie...">Professionnels/professionnelles en thérapie et en évaluation</MenuItem>,
        <MenuItem value="Technologues et techniciens/techniciennes..." className={classes.menuItem} key="Technologues et techniciens/techniciennes...">Technologues et techniciens/techniciennes des sciences de la santé</MenuItem>
      );
    }

    // Add other conditions similarly

    return items;
  };

  return (
    <div>
      <Box>
        <Text>Désignation de l'emploi *</Text>
      </Box>
      <FormControl className={classes.formControl}>
        <Select
          labelId="select-label"
          id="job-type-select"
          value={value}
          onChange={handleChange}
          className={classes.select}
          displayEmpty
          variant="outlined"
        >
          <MenuItem value="" disabled>
            Veuillez sélectionner
          </MenuItem>
          {renderMenuItems()}
        </Select>
      </FormControl>
    </div>
  );
};

export default JobType;