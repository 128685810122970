import React from 'react';
import { Select, MenuItem, FormControl, InputLabel, Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 250,
    maxWidth: 250,
    marginTop: '12px',
    marginBottom: '12px',
  },
  select: {
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },
  menuItem: {
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },
}));

const Passtype = ({ selectedValue, handleSelectChange }) => {
  const classes = useStyles();

  const options = [
    { value: '0', label: 'Passeport - ordinaire/régulier' },
    { value: '1', label: 'Passeport - diplomatique' },
    { value: '2', label: 'Passeport - officiel' },
    { value: '3', label: 'Passeport - service' },
    { value: '4', label: 'Document de voyage d\'urgence/provisoire' },
    { value: '5', label: 'Titre de voyage pour réfugié' },
    { value: '6', label: 'Permis pour rentrer aux États-Unis (I-327)' },
    { value: '7', label: 'Titre de voyage pour réfugiés des États-Unis (I-571)' },
  ];

  return (
    <>
      <Typography>Quel document de voyage avez-vous l'intention d'utiliser pour venir au Canada ? *</Typography>
      <Box mt="12px" mb="12px" maxWidth="240px">
        <FormControl className={classes.formControl}>
          <InputLabel id="pass-type-label"></InputLabel>
          <Select
            labelId="pass-type-label"
            id="pass-type-select"
            value={selectedValue}
            onChange={(event) => handleSelectChange(event.target.value)}
            className={classes.select}
            displayEmpty
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value} className={classes.menuItem}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </>
  );
};

export default Passtype;
