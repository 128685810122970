import React from 'react';
import { Text } from "@radix-ui/themes";
import { Select, MenuItem, FormControl } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    maxWidth:240,
    minWidth: 240,
    marginTop: '12px',
    marginBottom: '12px',
  },
  select: {
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },
  menuItem: {
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },
}));

const Occupation = ({ selectedValue2, handleSelectChange2 }) => {
  const classes = useStyles();

  return (
    <div>
      <Text>Type d'activité *</Text>
      <FormControl className={classes.formControl}>
        <Select
          labelId="select-label"
          id="occupation-select"
          value={selectedValue2}
          onChange={(event) => handleSelectChange2(event.target.value)}
          className={classes.select}
          displayEmpty
          variant="outlined"
        >
          <MenuItem value="" disabled>
            Veuillez sélectionner
          </MenuItem>
          <MenuItem value="0" className={classes.menuItem}>
            Affaires, finance et administration
          </MenuItem>
          <MenuItem value="1" className={classes.menuItem}>
            Arts, culture, sports et loisirs
          </MenuItem>
          <MenuItem value="2" className={classes.menuItem}>
            Chômeur
          </MenuItem>
          <MenuItem value="3" className={classes.menuItem}>
            Emplois dans les ventes et les services
          </MenuItem>
          <MenuItem value="4" className={classes.menuItem}>
            Enseignement, droit et services sociaux, communautaires et gouvernementaux
          </MenuItem>
          <MenuItem value="5" className={classes.menuItem}>
            Étudiant
          </MenuItem>
          <MenuItem value="6" className={classes.menuItem}>
            Fabrication et services d'utilité publique
          </MenuItem>
          <MenuItem value="7" className={classes.menuItem}>
            Forces armées
          </MenuItem>
          <MenuItem value="8" className={classes.menuItem}>
            Gestion
          </MenuItem>
          <MenuItem value="9" className={classes.menuItem}>
            Métiers, transport, machinerie et domaines apparentés
          </MenuItem>
          <MenuItem value="10" className={classes.menuItem}>
            Personne au foyer
          </MenuItem>
          <MenuItem value="11" className={classes.menuItem}>
            Ressources naturelles, agriculture et production connexe
          </MenuItem>
          <MenuItem value="12" className={classes.menuItem}>
            Retraité
          </MenuItem>
          <MenuItem value="13" className={classes.menuItem}>
            Sciences naturelles et appliquées et domaines apparentés
          </MenuItem>
          <MenuItem value="14" className={classes.menuItem}>
            Secteur de la santé
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default Occupation;
