import React from 'react';
import { Select, MenuItem, FormControl, InputLabel, Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 250,
    maxWidth: 250,
    marginTop: '35px',
    marginBottom: '12px',
  },
  select: {
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },
  menuItem: {
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },
}));

const SelectCountry = ({ handleCountrySelect1, country1, handleCountrySelect2, country2 }) => {
  const classes = useStyles();

  const countries1 = [
    { value: 'Allemagne, République fédérale', label: 'D (Allemagne, République fédérale)' },
    { value: 'Andorre', label: 'AND (Andorre)' },
    { value: 'Australie', label: 'AUS (Australie)' },
    { value: 'Autriche', label: 'AUT (Autriche)' },
    { value: 'Bahamas', label: 'BHS (Bahamas)' },
    { value: 'Barbade', label: 'BRB (Barbade)' },
    { value: 'Belgique', label: 'BEL (Belgique)' },
    { value: 'Brunei Darussalam', label: 'BRN (Brunei Darussalam)' },
    { value: 'Bulgarie', label: 'BGR (Bulgarie)' },
    { value: 'Chili', label: 'CHL (Chili)' },
    { value: 'Chypre', label: 'CYP (Chypre)' },
    { value: 'Corée du Sud', label: 'KOR (Corée du Sud)' },
    { value: 'Croatie', label: 'HRV (Croatie)' },
    { value: 'Danemark', label: 'DNK (Danemark)' },
    { value: 'Émirats arabes unis', label: 'ARE (Émirats arabes unis)' },
    { value: 'Espagne', label: 'ESP (Espagne)' },
    { value: 'Estonie', label: 'EST (Estonie)' },
    { value: 'Finlande', label: 'FIN (Finlande)' },
    { value: 'France', label: 'FRA (France)' },
    { value: 'Grèce', label: 'GRC (Grèce)' },
    { value: 'Hongrie', label: 'HUN (Hongrie)' },
    { value: 'Îles Salomon', label: 'SLB (Îles Salomon)' },
    { value: 'Irlande', label: 'IRL (Irlande)' },
    { value: 'Islande', label: 'ISL (Islande)' },
    { value: 'Italie', label: 'ITA (Italie)' },
    { value: 'Japon', label: 'JPN (Japon)' },
    { value: 'Lettonie', label: 'LVA (Lettonie)' },
    { value: 'Liechtenstein', label: 'LIE (Liechtenstein)' },
    { value: 'Lituanie', label: 'LTU (Lituanie)' },
    { value: 'Luxembourg', label: 'LUX (Luxembourg)' },
    { value: 'Malte', label: 'MLT (Malte)' },
    { value: 'Monaco', label: 'MCO (Monaco)' },
    { value: 'Norvège', label: 'NOR (Norvège)' },
    { value: 'Nouvelle-Zélande', label: 'NZL (Nouvelle-Zélande)' },
    { value: 'Papouasie-Nouvelle-Guinée', label: 'PNG (Papouasie-Nouvelle-Guinée)' },
    { value: 'Pays-Bas', label: 'NLD (Pays-Bas)' },
    { value: 'Pologne', label: 'POL (Pologne)' },
    { value: 'Portugal', label: 'PRT (Portugal)' },
    { value: 'République tchèque', label: 'CZE (République tchèque)' },
    { value: 'Roumanie', label: 'ROU (Roumanie)' },
    { value: 'R.-U. Terr. brit. outre-mer', label: 'GBD (R.-U. Terr. brit. outre-mer)' },
    { value: 'R.-U. - Ress. brit. outre-mer', label: 'GBN (R.-U. - Ress. brit. outre-mer)' },
    { value: 'R.-U. - Cit. brit. d\'outre-mer', label: 'GBO (R.-U. - Cit. brit. d\'outre-mer)' },
    { value: 'R.-U. - Citoyen britannique', label: 'GBR (R.-U. - Citoyen britannique)' },
    { value: 'R.-U. - Sujet britannique', label: 'GBS (R.-U. - Sujet britannique)' },
    { value: 'Saint-Marin', label: 'SMR (Saint-Marin)' },
    { value: 'Samoa', label: 'WSM (Samoa)' },
    { value: 'Singapour', label: 'SGP (Singapour)' },
    { value: 'Slovaquie', label: 'SVK (Slovaquie)' },
    { value: 'Slovénie', label: 'SVN (Slovénie)' },
    { value: 'Suède', label: 'SWE (Suède)' },
    { value: 'Suisse', label: 'CHE (Suisse)' },
    { value: 'Anguilla', label: 'ANG (Anguilla)' },
    { value: 'Bermudes', label: 'BMU (Bermudes)' },
    { value: 'Îles Turks et Caïques', label: 'TCA (Îles Turks et Caïques)' },
    { value: 'Îles Caïmans', label: 'CYM (Îles Caïmans)' },
    { value: 'Montserrat', label: 'MSR (Montserrat)' },
    { value: 'Îles Vierges britanniques', label: 'VGB (Îles Vierges britanniques)' },
    { value: 'Sainte-Hélène', label: 'SHN (Sainte-Hélène)' },
  ];

  const countries2 = [
    { value: 'D', label: 'Allemagne, Rép Fédérale' },
    { value: 'AND', label: 'Andorre' },
    { value: 'AUS', label: 'Australie' },
    { value: 'AUT', label: 'Autriche' },
    { value: 'BHS', label: 'Bahamas' },
    { value: 'BRB', label: 'Barbade' },
    { value: 'BEL', label: 'Belgique' },
    { value: 'BRN', label: 'Brunéi Darussalam' },
    { value: 'BGR', label: 'Bulgarie' },
    { value: 'CHL', label: 'Chili' },
    { value: 'CYP', label: 'Chypre' },
    { value: 'KOR', label: 'Corée, Sud' },
    { value: 'HRV', label: 'Croatie' },
    { value: 'DNK', label: 'Danemark' },
    { value: 'ARE', label: 'Émirats arabes unis' },
    { value: 'ESP', label: 'Espagne' },
    { value: 'EST', label: 'Estonie' },
    { value: 'FIN', label: 'Finlande' },
    { value: 'FRA', label: 'France' },
    { value: 'GRC', label: 'Grèce' },
    { value: 'HUN', label: 'Hongrie' },
    { value: 'SLB', label: 'Îles Salomon' },
    { value: 'IRL', label: 'Irlande' },
    { value: 'ISL', label: 'Islande' },
    { value: 'ITA', label: 'Italie' },
    { value: 'JPN', label: 'Japon' },
    { value: 'LVA', label: 'Lettonie' },
    { value: 'LIE', label: 'Liechtenstein' },
    { value: 'LTU', label: 'Lituanie' },
    { value: 'LUX', label: 'Luxembourg' },
    { value: 'MLT', label: 'Malte' },
    { value: 'MEX', label: 'Mexique' },
    { value: 'MCO', label: 'Monaco' },
    { value: 'NOR', label: 'Norvège' },
    { value: 'NZL', label: 'Nouvelle-Zélande' },
    { value: 'PNG', label: 'Papouasie-Nouvelle-Guinée' },
    { value: 'NLD', label: 'Pays-Bas, Les' },
    { value: 'POL', label: 'Pologne' },
    { value: 'PRT', label: 'Portugal' },
    { value: 'CZE', label: 'Tchèque, République' },
    { value: 'ROU', label: 'Roumanie' },
    { value: 'GBD', label: 'R.-U. Terr. brit. outre-mer' },
    { value: 'GBN', label: 'R.-U. - Ress. brit. outre-mer' },
    { value: 'GBO', label: 'R.-U. - Cit. brit. d\'outre-mer' },
    { value: 'GBR', label: 'R.-U. - Citoyen britannique' },
    { value: 'SMR', label: 'Saint-Marin' },
    { value: 'WSM', label: 'Samoa' },
    { value: 'SGP', label: 'Singapour' },
    { value: 'SVK', label: 'Slovaquie' },
    { value: 'SVN', label: 'Slovénie' },
    { value: 'SWE', label: 'Suède' },
    { value: 'CHE', label: 'Suisse' },
    { value: 'ANG', label: 'Anguilla' },
    { value: 'BMU', label: 'Bermudes' },
    { value: 'TCA', label: 'Îles Turks et Caïques' },
    { value: 'CYM', label: 'Caïmans, Îles' },
    { value: 'MSR', label: 'Montserrat' },
    { value: 'VGB', label: 'Îles Vierges britanniques' },
    { value: 'SHN', label: 'Sainte-Hélène' },
  ];

  return (
    <>
      <Typography>Veuillez choisir le code du pays existant sur votre passport *</Typography>
      <Box mb="12px" maxWidth="240px">
        <FormControl className={classes.formControl}>
          <InputLabel id="country-select1-label"></InputLabel>
          <Select
            labelId="country-select1-label"
            id="country-select1"
            value={country1}
            onChange={(event) => handleCountrySelect1(event.target.value)}
            className={classes.select}
            displayEmpty
          >
            {countries1.map((country) => (
              <MenuItem key={country.value} value={country.value} className={classes.menuItem}>
                {country.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Typography>Veuillez indiquer votre nationalité. *</Typography>
      <Box mt="0px" mb="12px" maxWidth="240px">
        <FormControl className={classes.formControl}>
          <InputLabel id="country-select2-label"></InputLabel>
          <Select
            labelId="country-select2-label"
            id="country-select2"
            value={country2}
            onChange={(event) => handleCountrySelect2(event.target.value)}
            className={classes.select}
            displayEmpty
          >
            {countries2.map((country) => (
              <MenuItem key={country.value} value={country.value} className={classes.menuItem}>
                {country.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </>
  );
};

export default SelectCountry;
