import React from 'react'
import {Flex,Text,Radio} from "@radix-ui/themes"

const SexPicker = ({handleSexChange}) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Flex alignItems="center" justifyContent="center" m="20px">
      <Text mr="30px">
        Sexe
      </Text>
      <Flex ml="20px" mb="25px" mt="12px" asChild gap="2" alignItems="center">
                  <Text as="label" size="2">
                    <Radio
                      size="1"
                      name="sex"
                      value="male"
                      onChange={() => handleSexChange("male")}
                      color="indigo"
                    />
                    Masculin
                    <Radio
                      size="1"
                      name="sex"
                      value="female"
                      onChange={() => handleSexChange("female")}
                      color="indigo"
                    />
                    Feminin
                    <Radio
                      size="1"
                      name="sex"
                      value="other"
                      onChange={() => handleSexChange("Other")}
                      color="indigo"
                    />
                    Un autre genre
                  </Text>
                </Flex>
    </Flex>
  </div>
  )
}

export default SexPicker