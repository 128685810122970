import React, { useState, useEffect } from 'react';
import { Layout, Menu, Button } from 'antd';
import { DashboardOutlined, SettingOutlined } from '@ant-design/icons';
import './Sidebar.css'; // Import the CSS file
import Dashcont from './Dashcont';
import Utilisateur from './Utilisateur'; // Import the new component
import { useNavigate } from 'react-router-dom';

const { Sider, Content } = Layout;

const Sidebar = () => {
  const [activeComponent, setActiveComponent] = useState('Dashcont');
  const navigate = useNavigate();

  useEffect(() => {
    const accessToken = sessionStorage.getItem('accessToken');
    if (!accessToken) {
      navigate('/Signup'); // Redirect to login if no access token is found
    }
  }, [navigate]);

  const handleMenuClick = (e) => {
    setActiveComponent(e.key);
  };

  const handleLogout = () => {
    sessionStorage.removeItem('accessToken');
    navigate('/Signup');
  };

  return (
    <Layout>
      <Sider>
        <Menu
          theme="dark"
          mode="inline"
          style={{ marginTop: '80px', padding: '10px' }}
          onClick={handleMenuClick}
        >
          <Menu.Item
            key="Dashcont"
            icon={<DashboardOutlined />}
            className="centered-menu-item"
            style={{ padding: '20px' }} // Add inline padding
          >
            AVE
          </Menu.Item>
          <Menu.Item
            key="Utilisateur"
            icon={<SettingOutlined />}
            className="centered-menu-item"
            style={{ padding: '20px' }} // Add inline padding
          >
            Utilisateur
          </Menu.Item>
          <Button className="logout-button" onClick={handleLogout}>
            Se Deconnecter
          </Button>
        </Menu>
      </Sider>
      <Layout>
        <Content style={{ padding: '24px', minHeight: '100vh' }}>
          {activeComponent === 'Dashcont' ? <Dashcont /> : <Utilisateur />}
        </Content>
      </Layout>
    </Layout>
  );
};

export default Sidebar;
