import React from 'react'
import {Text,Flex,Radio} from "@radix-ui/themes"

const Refused = ({handleRadioChange,title,name}) => {
  return (
    <div><Text>{title}</Text>
                <Flex ml="20px" mb="25px" mt="12px" asChild gap="2" alignItems="center">
                  <Text as="label" size="2">
                    <Radio
                      size="1"
                      name={name}
                      value="1"
                      onValueChange={() => handleRadioChange("1")}
                      color="indigo"
                    />
                    Oui
                    <Radio
                      size="1"
                      name={name}
                      value="2"
                      onValueChange={() => handleRadioChange("2")}
                      color="indigo"
                    />
                    Non
                    
                  </Text></Flex></div>
  )
}

export default Refused