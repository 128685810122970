import React from 'react';
import logo from "./../assets/media/logomob.png";
import { Card } from '@radix-ui/themes';
import { Button } from "antd";
import { useNavigate  } from 'react-router-dom';

const MobileHome = () => {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate('/Formulaire');
    };

  return (
    <>
      <Card m="20px" style={{ boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}>
        <div style={{ width: '100%', height: '60vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundColor: '#ffffff', fontFamily: 'Inter, sans-serif', padding: '20px' }}>
          <div>
            <img
              src={logo}
              alt="Description of the image"
            />
            
            <h1 style={{ color: '#EE1B45', fontSize: '24px', margin: '0', paddingBottom: '10px' }}>
              Autorisation de Voyage Canada
            </h1>
            <p style={{ color: 'black', fontSize: '16px', maxWidth: '600px', margin: '0', paddingTop: '10px' }}>
              L'AVE, ou Autorisation de Voyage Électronique, est un document officiel qui permet aux voyageurs de certains pays de se rendre au Canada pour des séjours de courte durée.
            </p>
          </div>
        </div>
      </Card>
      <Button
        style={{
          backgroundColor: '#EE1B45',
          color: '#ffffff',
          margin: '40px',
          width: "80%",
          padding: '10px 20px',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
          transition: 'background-color 0.3s ease, transform 0.3s ease',
        }}
        size="large"
        onMouseDown={(e) => e.currentTarget.style.transform = 'scale(0.9)'}
        onMouseUp={(e) => e.currentTarget.style.transform = 'scale(1)'}
        onTouchStart={(e) => e.currentTarget.style.transform = 'scale(0.9)'}
        onTouchEnd={(e) => e.currentTarget.style.transform = 'scale(1)'}
        onClick={handleButtonClick}
      >
        Demander en ligne
      </Button>
    </>
  );
};

export default MobileHome;
