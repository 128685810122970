import React, { useEffect } from 'react';
import { Flex, Text } from '@radix-ui/themes';
import Form from "./about/Form";

const About = () => {
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = ''; // This will trigger the confirmation dialog
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <Flex direction="column" align="center" justify="center" gap="3">
      <Form />
    </Flex>
  );
};

export default About;
