import React, { useState } from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 240,
    maxWidth: 240,
  },
  select: {
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },
  menuItem: {
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },
}));

const CustomSelector = ({ onSelectChange }) => {
  const classes = useStyles();
  const [selectedOption, setSelectedOption] = useState('');

  const options = [
    "Un membre de la famille ou un ami",
    "Un membre d'une organisation non gouvernementale ou religieuse",
    "Un membre du Collège des consultants en immigration et en citoyenneté (CCIC)",
    "Un membre d'un barreau d'une province ou d'un territoire du Canada",
    "Un membre de la Chambre des notaires du Québec",
    "Un agent de voyage"
  ];

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
    onSelectChange(event.target.value);
  };

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="select-label"></InputLabel>
      <Select
        labelId="select-label"
        id="custom-select"
        value={selectedOption}
        onChange={handleSelectChange}
        className={classes.select}
        displayEmpty
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={option} className={classes.menuItem}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomSelector;
