import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import NavBar from './components/navbar';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import FAQ from './components/FAQ';
import { Flex } from '@radix-ui/themes';
import Footer from "./components/Footer";
import Payments from "./Payments";
import Success from './components/about/Success';
import Cancel from './components/about/Cancel';
import Suivi from './components/Suivi';
import Signup from './components/Signup';
import Dashboard from './components/Dashboard';

const AppContent = () => {
  const location = useLocation();
  const isSidebarRoute = location.pathname === '/Dashboard';

  return (
    <Flex direction="column" minHeight="100vh">
      {!isSidebarRoute && <NavBar />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Formulaire" element={<About />} />
        <Route path="/Buy" element={<Payments/>}/>
        <Route path="/contact" element={<Contact />} />
        <Route path="/FAQ" element={<FAQ />} />
        <Route path="/Success" element={<Success/>}/>
        <Route path="/Cancel" element={<Cancel/>}/>
        <Route path="/Suivi" element={<Suivi/>}/>
        <Route path="/Signup" element={<Signup/>}/>
        <Route path="/Dashboard" element={<Dashboard/>}/>
      </Routes>
      {!isSidebarRoute && <Footer />}
    </Flex>
  );
};

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;