import React, { useEffect, useState } from 'react';
import { Button, Segmented, Space, Switch, Table, Typography, Tag, Flex, Spin } from 'antd';
import { DeleteOutlined, CheckOutlined, ReloadOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaCopy } from 'react-icons/fa';
import { API_URL } from '../config';

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    width: 100,
  },
  {
    title: 'FistName',
    dataIndex: 'firstName',
    width: 120,
  },
  {
    title: 'LastName',
    dataIndex: 'lastName',
  },
];

// Mapping object for selectedValue
const selectedValueMapping = {
  0: 'Un membre de la famille ou un ami',
  1: 'Un membre d\'une organisation non gouvernementale ou religieuse',
  2: 'Un membre du Collège des consultants en immigration et en citoyenneté (CCIC)',
  3: 'Un membre d\'un barreau d\'une province ou d\'un territoire du Canada',
  4: 'Un membre de la Chambre des notaires du Québec',
  5: 'Un agent de voyage'
};

// Function to get the text based on selectedValue
const getSelectedValueText = (selectedValue) => {
  return selectedValueMapping[selectedValue] || 'Unknown';
};

// Mapping object for DocumentType
const documentTypeMapping = {
  0: 'Passeport - ordinaire/régulier',
  1: 'Passeport - diplomatique',
  2: 'Passeport - officiel',
  3: 'Passeport - service',
  4: 'Document de voyage d\'urgence/provisoire',
  5: 'Titre de voyage pour réfugié',
  6: 'Permis pour rentrer aux États-Unis (I-327)',
  7: 'Titre de voyage pour réfugiés des États-Unis (I-571)'
};

// Function to get the text based on DocumentType
const getDocumentTypeText = (documentType) => {
  return documentTypeMapping[documentType] || 'Unknown';
};

const Dashcont = () => {
  const [fixed, setFixed] = useState(true);
  const [bordered, setBordered] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const [empty, setEmpty] = useState(false);
  const [count, setCount] = useState(10000);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const tblRef = React.useRef(null);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        setMessage(null);
      }, 3000); // Clear the message after 3 seconds
      return () => clearTimeout(timer);
    }
  }, [message]);

  const showMessage = (type, content) => {
    setMessage({ type, content });
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      // Retrieve the access token from sessionStorage
      const accessToken = sessionStorage.getItem('accessToken');

      if (!accessToken) {
        throw new Error('Access token not found');
      }

      const response = await fetch(`${API_URL}/mymodel/`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      setData(result);
    } catch (error) {
      showMessage('error', 'Error fetching data: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleMarkAsProcessed = async (id) => {
    setLoading(true);
    const accessToken = sessionStorage.getItem('accessToken');
    try {
      const response = await fetch(`${API_URL}/mymodel/${id}/mark-as-processed/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
      });
      if (response.ok) {
        // Refetch the data to reflect the change
        await fetchData();
      } else {
        showMessage('error', 'Error marking as processed: ' + response.statusText);
      }
    } catch (error) {
      showMessage('error', 'Error marking as processed: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    setLoading(true);
    const accessToken = sessionStorage.getItem('accessToken');
    try {
      const response = await fetch(`${API_URL}/mymodel/${id}/`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
      });
      if (response.ok) {
        // Refetch the data to reflect the change
        await fetchData();
      } else {
        showMessage('error', 'Error deleting record: ' + response.statusText);
      }
    } catch (error) {
      showMessage('error', 'Error deleting record: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const fixedColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 100,
      fixed: 'left',
    },
    {
      title: 'Prénom',
      dataIndex: 'firstName',
      width: 120,
      fixed: 'left',
    },
    {
      title: 'Nom',
      dataIndex: 'Nom',
      width: 120,
      fixed: 'left',
    },
    {
      title: 'Date Naissance',
      dataIndex: 'Birthdate',
      width: 200,
    },
    {
      title: 'Nationalité',
      dataIndex: 'PassNationality',
      width: 300,
    },
    {
      title: 'Numéro de Téléphone',
      dataIndex: 'phoneNumber',
      width: 300,
    },
    {
      title: 'Numéro de Fax',
      dataIndex: 'faxNumber',
      width: 300,
    },
    {
      title: 'Payment',
      key: 'status',
      dataIndex: 'isPaid',
      width: 100,
      render: (status) => (
        <Tag color={status ? 'red' : 'green'}>
          {status ? 'Annulé' : 'Traité'}
        </Tag>
      ),
    },
    {
      title: 'Traitement',
      key: 'status',
      dataIndex: 'ProccesStatus',
      width: 100,
      render: (status) => (
        <Tag color={status ? 'green' : 'orange'}>
          {status ? 'Traité' : 'Non-traité'}
        </Tag>
      ),
    },
    {
      title: 'Action',
      width: 250,
      fixed: 'right',
      render: (_, record) => (
        <Space>
          <Typography.Link onClick={() => handleMarkAsProcessed(record.id)}>
            <CheckOutlined /> Marquer comme traité
          </Typography.Link>
          <Typography.Link onClick={() => handleDelete(record.id)}>
            <DeleteOutlined /> Supprimer
          </Typography.Link>
        </Space>
      ),
    },
  ];

  const mergedColumns = React.useMemo(() => {
    if (!fixed) {
      return columns;
    }
    if (!expanded) {
      return fixedColumns;
    }
    return fixedColumns.map((col) => ({
      ...col,
      onCell: undefined,
    }));
  }, [expanded, fixed]);

  return (
    <div
      style={{
        padding: 64,
      }}
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        <Button type="primary" icon={<ReloadOutlined />} onClick={fetchData} loading={loading}>
          Refresh
        </Button>
        <Spin spinning={loading}>
          <Table
            bordered={bordered}
            virtual
            columns={mergedColumns}
            tableLayout="auto"
            scroll={{
              x: 2000,
              y: 1500,
            }}
            rowKey="id"
            expandable={{
              columnWidth: 48,
              expandedRowRender: (record) => (
                <div>
              <Flex>
                <div style={{ margin: '20px' }}>
                  <p>
                    <strong>Nature de Voyage:</strong> {record.natureVoyage}
                    <CopyToClipboard text={record.natureVoyage}>
                      <FaCopy style={{ marginLeft: '8px', cursor: 'pointer' }} />
                    </CopyToClipboard>
                  </p>
                  <p>
                    <strong>Demande Pour Autre Personne:</strong> {record.identit2}
                    <CopyToClipboard text={record.identit2}>
                      <FaCopy style={{ marginLeft: '8px', cursor: 'pointer' }} />
                    </CopyToClipboard>
                  </p>
                  <p>
                    <strong>Nationalité:</strong> {record.PassNationality}
                    <CopyToClipboard text={record.PassNationality}>
                      <FaCopy style={{ marginLeft: '8px', cursor: 'pointer' }} />
                    </CopyToClipboard>
                  </p>
                  <p>
                    <strong>Numéro de Téléphone:</strong> {record.phoneNumber}
                    <CopyToClipboard text={record.phoneNumber}>
                      <FaCopy style={{ marginLeft: '8px', cursor: 'pointer' }} />
                    </CopyToClipboard>
                  </p>
                  <p>
                    <strong>Numéro de Fax:</strong> {record.faxNumber}
                    <CopyToClipboard text={record.faxNumber}>
                      <FaCopy style={{ marginLeft: '8px', cursor: 'pointer' }} />
                    </CopyToClipboard>
                  </p>
                  <p>
                    <strong>Paiment:</strong> {record.isPaid ? 'Annulé' : 'Traité'}
                    <CopyToClipboard text={record.isPaid ? 'Annulé' : 'Traité'}>
                      <FaCopy style={{ marginLeft: '8px', cursor: 'pointer' }} />
                    </CopyToClipboard>
                  </p>
                </div>
                <div style={{ margin: "20px" }}>
                  <p>
                    <strong>Nom:</strong> {record.Nom}
                    <CopyToClipboard text={record.Nom}>
                      <FaCopy style={{ marginLeft: '8px', cursor: 'pointer' }} />
                    </CopyToClipboard>
                  </p>
                  <p>
                    <strong>prenom:</strong> {record.Prenom}
                    <CopyToClipboard text={record.Prenom}>
                      <FaCopy style={{ marginLeft: '8px', cursor: 'pointer' }} />
                    </CopyToClipboard>
                  </p>
                  <p>
                    <strong>Numéro d'adresse:</strong> {record.addressNumber}
                    <CopyToClipboard text={record.addressNumber}>
                      <FaCopy style={{ marginLeft: '8px', cursor: 'pointer' }} />
                    </CopyToClipboard>
                  </p>
                  <p>
                    <strong>Pays:</strong> {record.country}
                    <CopyToClipboard text={record.country}>
                      <FaCopy style={{ marginLeft: '8px', cursor: 'pointer' }} />
                    </CopyToClipboard>
                  </p>
                  <p>
                    <strong>Adresse Email:</strong> {record.email}
                    <CopyToClipboard text={record.email}>
                      <FaCopy style={{ marginLeft: '8px', cursor: 'pointer' }} />
                    </CopyToClipboard>
                  </p>
                  <p>
                    <strong>Nom de l'entreprise ou l'organisation:</strong> {record.isPaid ? 'Traité' : 'Annulé'}
                    <CopyToClipboard text={record.isPaid ? 'Traité' : 'Annulé'}>
                      <FaCopy style={{ marginLeft: '8px', cursor: 'pointer' }} />
                    </CopyToClipboard>
                  </p>
                </div>
                <div style={{ margin: "20px"}}>
                  <p>
                    <strong>Identifiant organisation:</strong> {record.memberId}
                    <CopyToClipboard text={record.memberId}>
                      <FaCopy style={{ marginLeft: '8px', cursor: 'pointer' }} />
                    </CopyToClipboard>
                  </p>
                  <p>
                    <strong>Province:</strong> {record.province}
                    <CopyToClipboard text={record.province}>
                      <FaCopy style={{ marginLeft: '8px', cursor: 'pointer' }} />
                    </CopyToClipboard>
                  </p>
                  <p>
                    <strong>Rue:</strong> {record.street}
                    <CopyToClipboard text={record.street}>
                      <FaCopy style={{ marginLeft: '8px', cursor: 'pointer' }} />
                    </CopyToClipboard>
                  </p>
                  <p>
                    <strong>Postal Code:</strong> {record.postalCode}
                    <CopyToClipboard text={record.postalCode}>
                      <FaCopy style={{ marginLeft: '8px', cursor: 'pointer' }} />
                    </CopyToClipboard>
                  </p>
                  <p>
                    <strong>Ville :</strong> {record.city}
                    <CopyToClipboard text={record.city}>
                      <FaCopy style={{ marginLeft: '8px', cursor: 'pointer' }} />
                    </CopyToClipboard>
                  </p>
                  <p>
                    <strong>Autorisation Client:</strong> {record.authorizationChecked ? 'Autorisé' : 'non-autorisé'}
                    <CopyToClipboard text={record.isPaid ? 'Autorisé' : 'non-autorisé'}>
                      <FaCopy style={{ marginLeft: '8px', cursor: 'pointer' }} />
                    </CopyToClipboard>
                  </p>
                </div>
                <div style={{ margin: "20px" }}>
                  <p>
                    <strong>Type de membre:</strong> {getSelectedValueText(record.selectedValue)}
                    <CopyToClipboard text={getSelectedValueText(record.selectedValue)}>
                      <FaCopy style={{ marginLeft: '8px', cursor: 'pointer' }} />
                    </CopyToClipboard>
                  </p>
                  <p>
                    <strong>Type de Document:</strong> {getDocumentTypeText(record.DocumentType)}
                    <CopyToClipboard text={getDocumentTypeText(record.DocumentType)}>
                      <FaCopy style={{ marginLeft: '8px', cursor: 'pointer' }} />
                    </CopyToClipboard>
                  </p>
                  <p>
                    <strong>Code de Pays en passport:</strong> {record.PassCountryCode}
                    <CopyToClipboard text={record.PassCountryCode}>
                      <FaCopy style={{ marginLeft: '8px', cursor: 'pointer' }} />
                    </CopyToClipboard>
                  </p>
                  <p>
                    <strong>la Nationalité origine(sur passport):</strong> {record.PassNationality}
                    {/* A REVISER  */}
                    <CopyToClipboard text={record.PassNationality}>
                      <FaCopy style={{ marginLeft: '8px', cursor: 'pointer' }} />
                    </CopyToClipboard>
                  </p>
                  <p>
                    <strong>Pays de naissance (sur passport):</strong> {record.PassBirthCountry}
                    <CopyToClipboard text={record.PassBirthCountry}>
                      <FaCopy style={{ marginLeft: '8px', cursor: 'pointer' }} />
                    </CopyToClipboard>
                  </p>
                  <p>
                    <strong>Numéro de passport :</strong> {record.PassNumero}
                    <CopyToClipboard text={record.PassNumero}>
                      <FaCopy style={{ marginLeft: '8px', cursor: 'pointer' }} />
                    </CopyToClipboard>
                  </p>
                  <p>
                    <strong>Date de Naissance:</strong> {record.Birthdate}
                    <CopyToClipboard text={record.Birthdate}>
                      <FaCopy style={{ marginLeft: '8px', cursor: 'pointer' }} />
                    </CopyToClipboard>
                  </p>
                  
                </div>
                <div style={{ margin: "20px" }}>
                  <p>
                    <strong>Date d'expiration Passport:</strong> {record.Passexpiration}
                    <CopyToClipboard text={record.Passexpiration}>
                      <FaCopy style={{ marginLeft: '8px', cursor: 'pointer' }} />
                    </CopyToClipboard>
                  </p>
                  <p>
                    <strong>Date delivrance du passport:</strong> {record.PassDelivery}
                    <CopyToClipboard text={record.PassDelivery}>
                      <FaCopy style={{ marginLeft: '8px', cursor: 'pointer' }} />
                    </CopyToClipboard>
                  </p>
                  <p>
                    <strong>Ville de Naissance:</strong> {record.BirthCity}
                    <CopyToClipboard text={record.BirthCity}>
                      <FaCopy style={{ marginLeft: '8px', cursor: 'pointer' }} />
                    </CopyToClipboard>
                  </p>
                  <p>
                    <strong>sex :</strong> {record.sex}
                    <CopyToClipboard text={record.sex}>
                      <FaCopy style={{ marginLeft: '8px', cursor: 'pointer' }} />
                    </CopyToClipboard>
                  </p>
                  <p>
                    <strong>Pays d'origine':</strong> {record.LocalCountry}
                    <CopyToClipboard text={record.LocalCountry}>
                      <FaCopy style={{ marginLeft: '8px', cursor: 'pointer' }} />
                    </CopyToClipboard>
                  </p>
                  <p>
                    <strong>Status Martial :</strong> {record.MartialStatus}
                    <CopyToClipboard text={record.MartialStatus}>
                      <FaCopy style={{ marginLeft: '8px', cursor: 'pointer' }} />
                    </CopyToClipboard>
                  </p>
                  <p>
                    <strong>Code visa precedente:</strong> {record.PastVisa}
                    <CopyToClipboard text={record.PastVisa}>
                      <FaCopy style={{ marginLeft: '8px', cursor: 'pointer' }} />
                    </CopyToClipboard>
                  </p>
                  
                </div>
                <div style={{ margin: "20px" }}>
                  <p>
                    <strong>Ave Precendent code :</strong> {record.IdolVisa}
                    <CopyToClipboard text={record.IdolVisa}>
                      <FaCopy style={{ marginLeft: '8px', cursor: 'pointer' }} />
                    </CopyToClipboard>
                  </p>
                  <p>
                    <strong>Occupation :</strong> {record.Occupation}
                    <CopyToClipboard text={record.Occupation}>
                      <FaCopy style={{ marginLeft: '8px', cursor: 'pointer' }} />
                    </CopyToClipboard>
                  </p>
                  <p>
                    <strong>Ville d'Occupation':</strong> {record.JobCity}
                    <CopyToClipboard text={record.JobCity}>
                      <FaCopy style={{ marginLeft: '8px', cursor: 'pointer' }} />
                    </CopyToClipboard>
                  </p>
                  <p>
                    <strong>Année depart Occupation :</strong> {record.JobYearStart}
                    <CopyToClipboard text={record.JobYearStart}>
                      <FaCopy style={{ marginLeft: '8px', cursor: 'pointer' }} />
                    </CopyToClipboard>
                  </p>
                  <p>
                    <strong>Pays d'Occupation:</strong> {record.JobCountry}
                    <CopyToClipboard text={record.JobCountry}>
                      <FaCopy style={{ marginLeft: '8px', cursor: 'pointer' }} />
                    </CopyToClipboard>
                  </p>
                  <p>
                    <strong>Employeur ou université :</strong> {record.EmployerorUniversity}
                    <CopyToClipboard text={record.EmployerorUniversity}>
                      <FaCopy style={{ marginLeft: '8px', cursor: 'pointer' }} />
                    </CopyToClipboard>
                  </p>
                  <p>
                    <strong>Titre d'Occupation:</strong> {record.JobTitle}
                    <CopyToClipboard text={record.JobTitle}>
                      <FaCopy style={{ marginLeft: '8px', cursor: 'pointer' }} />
                    </CopyToClipboard>
                  </p>
                  
                </div>
              </Flex>
              <Flex>
              <div style={{ margin: "20px" }}>
                  <p>
                    <strong>Language de corresspondance :</strong> {record.Language}
                    <CopyToClipboard text={record.Language}>
                      <FaCopy style={{ marginLeft: '8px', cursor: 'pointer' }} />
                    </CopyToClipboard>
                  </p>
                  <p>
                    <strong>numéro apparetement :</strong> {record.AptNumber}
                    <CopyToClipboard text={record.AptNumber}>
                      <FaCopy style={{ marginLeft: '8px', cursor: 'pointer' }} />
                    </CopyToClipboard>
                  </p>
                  <p>
                    <strong>details addresse:</strong> {record.AdressDetails}
                    <CopyToClipboard text={record.AdressDetails}>
                      <FaCopy style={{ marginLeft: '8px', cursor: 'pointer' }} />
                    </CopyToClipboard>
                  </p>
                  <p>
                    <strong>CodePostal :</strong> {record.CodePostal}
                    <CopyToClipboard text={record.CodePostal}>
                      <FaCopy style={{ marginLeft: '8px', cursor: 'pointer' }} />
                    </CopyToClipboard>
                  </p>
                  <p>
                    <strong>Pays mére:</strong> {record.HomeCountry}
                    <CopyToClipboard text={record.HomeCountry}>
                      <FaCopy style={{ marginLeft: '8px', cursor: 'pointer' }} />
                    </CopyToClipboard>
                  </p>
                  <p>
                    <strong>Numéro de voirie ou d'immeuble ou nom de la maison:</strong> {record.HouseNumber}
                    <CopyToClipboard text={record.HouseNumber}>
                      <FaCopy style={{ marginLeft: '8px', cursor: 'pointer' }} />
                    </CopyToClipboard>
                  </p>
                  <p>
                    <strong>details adresse secondaire:</strong> {record.AdressDetails2}
                    <CopyToClipboard text={record.AdressDetails2}>
                      <FaCopy style={{ marginLeft: '8px', cursor: 'pointer' }} />
                    </CopyToClipboard>
                  </p>
                  
                </div>
                <div style={{ margin: "20px" }}>
                  <p>
                    <strong>connaissance de date voyage:</strong> {record.authorizationChecked ? 'oui' : 'non'}
                    <CopyToClipboard text={record.authorizationChecked ? 'oui' : 'non'}>
                      <FaCopy style={{ marginLeft: '8px', cursor: 'pointer' }} />
                    </CopyToClipboard>
                  </p>
                  <p>
                    <strong>date voyage(si il y a) :</strong> {record.TravelDate}
                    <CopyToClipboard text={record.TravelDate}>
                      <FaCopy style={{ marginLeft: '8px', cursor: 'pointer' }} />
                    </CopyToClipboard>
                  </p>
                  <p>
                    <strong>Refusé avant:</strong> {record.RefusedBefore ? 'oui' : 'non'}
                    <CopyToClipboard text={record.RefusedBefore ? 'oui' : 'non'}>
                      <FaCopy style={{ marginLeft: '8px', cursor: 'pointer' }} />
                    </CopyToClipboard>
                  </p>
                  <p>
                    <strong>passé ou record criminel :</strong> {record.CrimePast ? 'oui' : 'non'}
                    <CopyToClipboard text={record.CrimePast ? 'oui' : 'non'}>
                      <FaCopy style={{ marginLeft: '8px', cursor: 'pointer' }} />
                    </CopyToClipboard>
                  </p>
                  <p>
                    <strong>une friction avec la tuberculose:</strong> {record.Tuberculose ? 'oui' : 'non'}
                    <CopyToClipboard text={record.Tuberculose ? 'oui' : 'non'}>
                      <FaCopy style={{ marginLeft: '8px', cursor: 'pointer' }} />
                    </CopyToClipboard>
                  </p>
                  <p>
                    <strong>Numéro de voirie ou d'immeuble ou nom de la maison:</strong> {record.HouseNumber}
                    <CopyToClipboard text={record.HouseNumber}>
                      <FaCopy style={{ marginLeft: '8px', cursor: 'pointer' }} />
                    </CopyToClipboard>
                  </p>
                  <p>
                    <strong>details adresse secondaire:</strong> {record.AdressDetails2}
                    <CopyToClipboard text={record.AdressDetails2}>
                      <FaCopy style={{ marginLeft: '8px', cursor: 'pointer' }} />
                    </CopyToClipboard>
                  </p>
                  
                  
                </div>
                <div style={{ margin: "20px" }}>
                  <p>
                    <strong>adult/mineur:</strong> {record.minorrr}
                    <CopyToClipboard text={record.minorrr}>
                      <FaCopy style={{ marginLeft: '8px', cursor: 'pointer' }} />
                    </CopyToClipboard>
                  </p>
                  <p>
                    <strong>plan selecté :</strong> {record.selectedPlan}
                    <CopyToClipboard text={record.selectedPlan}>
                      <FaCopy style={{ marginLeft: '8px', cursor: 'pointer' }} />
                    </CopyToClipboard>
                  </p>
                  <p>
                    <strong>status traitement:</strong> {record.ProccesStatus ? 'oui' : 'non'}
                    <CopyToClipboard text={record.ProccesStatus ? 'oui' : 'non'}>
                      <FaCopy style={{ marginLeft: '8px', cursor: 'pointer' }} />
                    </CopyToClipboard>
                  </p>
                </div>
              </Flex>
              </div>
              ),
              rowExpandable: () => true, // Make all rows expandable
            }}
            dataSource={empty ? [] : data}
            pagination={false}
            ref={tblRef}
            rowSelection={
              expanded
                ? undefined
                : {
                    type: 'radio',
                    columnWidth: 48,
                  }
            }
          />
        </Spin>
      </Space>
    </div>
  );
};

export default Dashcont;
