import React, { useState } from 'react';
import { Card, Text, Button, Container, Flex, TextField, Box } from '@radix-ui/themes';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../config';

const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const response = await fetch(`${API_URL}/login/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username: email, password: password }),
      });

      if (response.ok) {
        const data = await response.json();
        const { access } = data;

        // Store the access token in sessionStorage
        sessionStorage.setItem('accessToken', access);

        // Redirect to the Dashboard
        navigate('/Dashboard');
      } else {
        // Handle login failure
        setErrorMessage("mots de passe ou nom d'utilisateur incorrect");
      }
    } catch (error) {
      // console.error('Erreur:', error);
      setErrorMessage('An error occurred. Please try again.');
    }
  };

  return (
    <Container size="2">
      <Card mt="160px" p="100px" style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}>
        <Flex m="40px">
          <Text size="8">Connectez-Vous</Text>
        </Flex>
        {errorMessage && (
          <Flex ml="40px" mt="5px" style={{ color: 'red' }}>
            <Text size="5">{errorMessage}</Text>
          </Flex>
        )}
        <Flex ml="40px">
          <Text size="5">Nom d'utilisateur</Text>
        </Flex>
        <Flex ml="40px" mt="5px">
          <TextField.Root
            placeholder="Tapez votre nom d'utilisateur..."
            style={{ width: '600px' }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Flex>
        <Flex mt="20px" ml="40px">
          <Text size="5">Mots de passe</Text>
        </Flex>
        <Flex ml="40px" mt="5px">
          <TextField.Root
            placeholder="Tapez votre mot de passe..."
            style={{ width: '600px' }}
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Flex>
        <Flex m="30px">
          <Button size="3" style={{ marginLeft: 'auto' }} onClick={handleLogin}>
            Me Connectez
          </Button>
        </Flex>
      </Card>
    </Container>
  );
};

export default Signup;
