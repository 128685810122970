import React, { useEffect } from 'react';
import { Container, Text, Grid, Card, Box, Flex, TextField, Radio, Checkbox } from '@radix-ui/themes';
import CustomSelector from './Customselector';

const Step3 = ({ formValues, selectedValue, onInputChange, onSelectChange, onCheckboxChange, onValidationChange, Showidentite }) => {

  const handleSelectChange = (value) => {
    onSelectChange(value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    onInputChange(name, value);
  };

  const handleCheckboxChange = (name, checked) => {
    onCheckboxChange(name, checked);
  };

  const validateForm = () => {
    const {
      name,
      addressNumber,
      country,
      phoneNumber,
      email,
      companyName,
      memberId,
      province,
      firstName,
      street,
      postalCode,
      city,
      faxNumber,
      isPaid,
      declarationChecked,
      authorizationChecked,
    } = formValues;

    const isValid =
      name &&
      addressNumber &&
      country &&
      phoneNumber &&
      email &&
      (selectedValue === '2' || selectedValue === '3' ? memberId : true) &&
      (selectedValue === '2' || selectedValue === '3' ? province : true) &&
      (selectedValue === '1' || selectedValue === '2' || selectedValue === '3' || selectedValue === '4' || selectedValue === '5' ? companyName : true) &&
      firstName &&
      street &&
      postalCode &&
      city &&
      declarationChecked &&
      authorizationChecked;

    onValidationChange(isValid);
  };

  useEffect(() => {
    validateForm();
  }, [formValues, selectedValue]);

  return (
    <>
      <Container size="2" >
        <Card m="20px" style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}>
          <div style={{ margin: "20px" }}>
            <Text size="6">Informations Personelles</Text>
          </div>
          <Grid columns={{ initial: '1', md: '2' }} gap="3" width="auto">
            <Box ml="20px" mt="20px" mb="35px" height="auto">
              {Showidentite && (
                <div>
                  <Text>Je suis *</Text>
                  <Flex mt="12px" mb="12px" direction="column" maxWidth="240px">
                    <CustomSelector onSelectChange={handleSelectChange} />
                    <Flex mr="20px" ml="20px" mt="20px">
                      <Text>Êtes-vous payé pour représenter le demandeur et compléter ce formulaire en son nom? *</Text>
                    </Flex>
                    <Flex ml="20px" mt="12px" asChild gap="2">
                      <Text as="label" size="2">
                        <Radio
                          size="1"
                          name="isPaid"
                          value="1"
                          checked={formValues.isPaid === '1'}
                          onChange={handleInputChange}
                          color="indigo"
                        />
                        Oui
                      </Text>
                    </Flex>
                    <Flex ml="20px" mb="12px" asChild gap="2">
                      <Text as="label" size="2">
                        <Radio
                          size="1"
                          name="isPaid"
                          value="2"
                          checked={formValues.isPaid === '2'}
                          onChange={handleInputChange}
                          color="indigo"
                        />
                        Non
                      </Text>
                    </Flex>
                  </Flex>
                </div>
              )}
              {(selectedValue === '2' || selectedValue === '3') && (
                <>
                  <Text color="gray">Numéro d'identification du membre *</Text>
                  <Flex mt="12px" mb="12px" direction="column" maxWidth="240px">
                    <Box maxWidth="240px">
                      <TextField.Root
                        size="2"
                        placeholder="Nom de l'organisation"
                        name="memberId"
                        value={formValues.memberId}
                        onChange={handleInputChange}
                      />
                    </Box>
                  </Flex>
                </>
              )}
              {selectedValue === '4' && (
                <>
                  <Text color="gray">Numéro d'identification du membre *</Text>
                  <Flex mt="12px" mb="12px" direction="column" maxWidth="240px">
                    <Box maxWidth="240px">
                      <TextField.Root
                        size="2"
                        placeholder="Votre nom içi"
                        name="memberId"
                        value={formValues.memberId}
                        onChange={handleInputChange}
                      />
                    </Box>
                  </Flex>
                </>
              )}
              <Text>Nom *</Text>
              <Flex mt="12px" mb="12px" direction="column" maxWidth="240px">
                <Box maxWidth="240px">
                  <TextField.Root
                    size="2"
                    placeholder="Votre nom içi"
                    name="name"
                    value={formValues.name}
                    onChange={handleInputChange}
                    color="indigo"
                  />
                </Box>
              </Flex>
              <Text>Prénom</Text>
              <Flex mt="12px" mb="12px" direction="column">
                <Box maxWidth="240px">
                  <TextField.Root
                    size="2"
                    placeholder="Votre prénom içi"
                    name="firstName"
                    value={formValues.firstName}
                    onChange={handleInputChange}
                    color="indigo"
                  />
                </Box>
              </Flex>
              <Text>Numéro de Téléphone *</Text>
              <Flex mt="12px" mb="12px" direction="column">
                <Box maxWidth="240px">
                  <TextField.Root
                    size="2"
                    name="phoneNumber"
                    value={formValues.phoneNumber}
                    onChange={handleInputChange}
                    color="indigo"
                  />
                </Box>
              </Flex>
              <Text>Adresse électronique *</Text>
              <Flex mt="12px" mb="12px" direction="column">
                <Box maxWidth="240px">
                  <TextField.Root
                    size="2"
                    placeholder="Votre Email içi"
                    name="email"
                    value={formValues.email}
                    onChange={handleInputChange}
                    color="indigo"
                  />
                </Box>
              </Flex>
            </Box>
            <Box height="auto" ml="20px" mt="20px">
              {(selectedValue === '1' || selectedValue === '2' || selectedValue === '3' || selectedValue === '4' || selectedValue === '5') && (
                <>
                  <Text color="gray">Nom de l'entreprise ou l'organisation *</Text>
                  <Flex mt="12px" mb="12px" direction="column" maxWidth="240px">
                    <Box maxWidth="240px">
                      <TextField.Root
                        size="2"
                        placeholder="Nom de l'organisation"
                        name="companyName"
                        value={formValues.companyName}
                        onChange={handleInputChange}
                        color="indigo"
                      />
                    </Box>
                  </Flex>
                </>
              )}
              {(selectedValue === '2' || selectedValue === '3') && (
                <>
                  <Text color="gray">De Quelle Province ou quel territoire *</Text>
                  <Flex mt="12px" mb="12px" direction="column">
                    <Box maxWidth="240px">
                      <TextField.Root
                        size="2"
                        name="province"
                        value={formValues.province}
                        onChange={handleInputChange}
                        color="indigo"
                      />
                    </Box>
                  </Flex>
                </>
              )}
              
              <Flex mt="12px" mb="12px" direction="column">
                <Text>Rue *</Text>
                <Box maxWidth="240px">
                  <TextField.Root
                    size="2"
                    name="street"
                    value={formValues.street}
                    onChange={handleInputChange}
                    color="indigo"
                  />
                </Box>
              </Flex>
              <Text>Numéro d'adresse *</Text>
              <Flex mt="12px" mb="12px" direction="column">
                <Box maxWidth="240px">
                  <TextField.Root
                    size="2"
                    name="addressNumber"
                    value={formValues.addressNumber}
                    onChange={handleInputChange}
                    color="indigo"
                  />
                </Box>
              </Flex>
              <Text>Code Postal *</Text>
              <Flex mt="12px" mb="12px" direction="column">
                <Box maxWidth="240px">
                  <TextField.Root
                    size="2"
                    name="postalCode"
                    value={formValues.postalCode}
                    onChange={handleInputChange}
                    color="indigo"
                  />
                </Box>
              </Flex>
              <Text>Ville *</Text>
              <Flex mt="12px" mb="12px" direction="column">
                <Box maxWidth="240px">
                  <TextField.Root
                    size="2"
                    name="city"
                    value={formValues.city}
                    onChange={handleInputChange}
                    color="indigo"
                  />
                </Box>
              </Flex>
              <Text>Pays *</Text>
              <Flex mt="12px" mb="12px" direction="column">
                <Box maxWidth="240px">
                  <TextField.Root
                    size="2"
                    placeholder="Votre Pays d'habitation içi"
                    name="country"
                    value={formValues.country}
                    onChange={handleInputChange}
                    color="indigo"
                  />
                </Box>
              </Flex>
            </Box>
          </Grid>

          <Flex m="20px">
            <Text size="6">Déclaration du Représentant *</Text>
          </Flex>
          <Flex ml="20px" mr="20px">
            <Text as="label" size="2">
              <Flex gap="2">
                <Checkbox
                  name="declarationChecked"
                  onCheckedChange={(checked) => handleCheckboxChange('declarationChecked', checked)}
                  color="indigo"
                />
                Je certifie que les coordonnées et les renseignements personnels fournis ci-dessus sont exacts, complets et véridiques.
              </Flex>
            </Text>
          </Flex>
          <Flex m="20px">
            <Text size="6">Autorisation du Représentant *</Text>
          </Flex>
          <Flex ml="20px" mr="20px" mb="35px">
            <Text as="label" size="2">
              <Flex gap="2">
                <Checkbox
                  name="authorizationChecked"
                  onCheckedChange={(checked) => handleCheckboxChange('authorizationChecked', checked)}
                  color="indigo"
                />
                Je comprends et j'accepte d'être la personne désignée par le demandeur pour gérer ses interactions ou celles d'un répondant avec Immigration, Réfugiés et Citoyenneté Canada ainsi qu'avec l'Agence des services frontaliers du Canada.
              </Flex>
            </Text>
          </Flex>
        </Card>
      </Container>
    </>
  );
};

export default Step3;
