import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import planeImage from './../assets/media/plane.png';
import { Grid, Box, Text, TextField, CheckboxGroup, Link, Button, Select } from '@radix-ui/themes';

const TwoSectionComponent = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1028);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1028);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Grid columns={{ initial: '1', md: '2' }} gap="3" width="auto">
      <Box m="20px" style={{ backgroundColor: 'var(--gray-a10)', borderRadius: 'var(--radius-3)' }} height={isMobile ? '300px' : '700px'} bg="green.200">
        <img
          src={planeImage}
          alt="Description of the image"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover', // Ensure the image covers the entire box
            borderRadius: 'var(--radius-3)', // Match the border-radius of the box
          }}
        />
      </Box>
      <Box p="20px" mt={isMobile ? "0px" : "45px"} m="20px" style={{ borderRadius: 'var(--radius-3)' }} height="800px" bg="green.200">
        <div style={{ margin: '20px' }}>
          <Text size="8">Demandez votre AVE facilement</Text>
        </div>
        <div style={{ margin: '20px', marginBottom: '40px' }}>
          <Text>Déja client ? </Text>
          <RouterLink to="/Suivi">suivi client</RouterLink>
        </div>
        <form>
          <div style={{ margin: '20px' }}>
            <Text>Adresse Email</Text>
          </div>
          <div>
            <TextField.Root size="2" m="20px" radius="large" placeholder="Votre Email" />
          </div>
          <div style={{ margin: '20px' }}>
            <Text>Numéro de telephone</Text>
          </div>
          <div>
            <TextField.Root size="2" m="20px" radius="large" placeholder="+33 XXX XXXXX" />
          </div>
          <div style={{ margin: '20px', marginTop: '45px' }}>
            <Text>Nationalité de Passport</Text>
          </div>
          <div>
            <Select.Root size="2" defaultValue="">
              <Select.Trigger placeholder="Choisissez votre pays." p="30px" m="20px" style={{ width: '240px' }} />
              <Select.Content>
                <Select.Item value="D">Allemagne, Rép Fédérale</Select.Item>
                <Select.Item value="AND">Andorre</Select.Item>
                <Select.Item value="AUS">Australie</Select.Item>
                <Select.Item value="AUT">Autriche</Select.Item>
                <Select.Item value="BHS">Bahamas</Select.Item>
                <Select.Item value="BRB">Barbade</Select.Item>
                <Select.Item value="BEL">Belgique</Select.Item>
                <Select.Item value="BRN">Brunéi Darussalam</Select.Item>
                <Select.Item value="BGR">Bulgarie</Select.Item>
                <Select.Item value="CHL">Chili</Select.Item>
                <Select.Item value="CYP">Chypre</Select.Item>
                <Select.Item value="KOR">Corée, Sud</Select.Item>
                <Select.Item value="HRV">Croatie</Select.Item>
                <Select.Item value="DNK">Danemark</Select.Item>
                <Select.Item value="ARE">Émirats arabes unis</Select.Item>
                <Select.Item value="ESP">Espagne</Select.Item>
                <Select.Item value="EST">Estonie</Select.Item>
                <Select.Item value="FIN">Finlande</Select.Item>
                <Select.Item value="FRA">France</Select.Item>
                <Select.Item value="GRC">Grèce</Select.Item>
                <Select.Item value="HUN">Hongrie</Select.Item>
                <Select.Item value="SLB">Îles Salomon</Select.Item>
                <Select.Item value="IRL">Irlande</Select.Item>
                <Select.Item value="ISL">Islande</Select.Item>
                <Select.Item value="ITA">Italie</Select.Item>
                <Select.Item value="JPN">Japon</Select.Item>
                <Select.Item value="LVA">Lettonie</Select.Item>
                <Select.Item value="LIE">Liechtenstein</Select.Item>
                <Select.Item value="LTU">Lituanie</Select.Item>
                <Select.Item value="LUX">Luxembourg</Select.Item>
                <Select.Item value="MLT">Malte</Select.Item>
                <Select.Item value="MEX">Mexique</Select.Item>
                <Select.Item value="MCO">Monaco</Select.Item>
                <Select.Item value="NOR">Norvège</Select.Item>
                <Select.Item value="NZL">Nouvelle-Zélande</Select.Item>
                <Select.Item value="PNG">Papouasie-Nouvelle-Guinée</Select.Item>
                <Select.Item value="NLD">Pays-Bas, Les</Select.Item>
                <Select.Item value="POL">Pologne</Select.Item>
                <Select.Item value="PRT">Portugal</Select.Item>
                <Select.Item value="CZE">Tchèque, République</Select.Item>
                <Select.Item value="ROU">Roumanie</Select.Item>
                <Select.Item value="GBD">R.-U. Terr. brit. outre-mer</Select.Item>
                <Select.Item value="GBN">R.-U. - Ress. brit. outre-mer</Select.Item>
                <Select.Item value="GBO">R.-U. - Cit. brit. d'outre-mer</Select.Item>
                <Select.Item value="GBR">R.-U. - Citoyen britannique</Select.Item>
                <Select.Item value="SMR">Saint-Marin</Select.Item>
                <Select.Item value="WSM">Samoa</Select.Item>
                <Select.Item value="SGP">Singapour</Select.Item>
                <Select.Item value="SVK">Slovaquie</Select.Item>
                <Select.Item value="SVN">Slovénie</Select.Item>
                <Select.Item value="SWE">Suède</Select.Item>
                <Select.Item value="CHE">Suisse</Select.Item>
                <Select.Item value="ANG">Anguilla</Select.Item>
                <Select.Item value="BMU">Bermudes</Select.Item>
                <Select.Item value="TCA">Îles Turks et Caïques</Select.Item>
                <Select.Item value="CYM">Caïmans, Îles</Select.Item>
                <Select.Item value="MSR">Montserrat</Select.Item>
                <Select.Item value="VGB">Îles Vierges britanniques</Select.Item>
                <Select.Item value="SHN">Sainte-Hélène</Select.Item>
              </Select.Content>
            </Select.Root>
          </div>
          <div style={{ margin: '20px', marginTop: '45px' }}>
            <CheckboxGroup.Root defaultValue={['1']} name="example">
              <CheckboxGroup.Item value="1">
                J'accepte <Link href="#" underline="hover">les termes utilisateur</Link>
              </CheckboxGroup.Item>
            </CheckboxGroup.Root>
          </div>
          <div style={{ marginTop: "50px" }}>
            <RouterLink to="/Suivi">
              <Button m="20px" variant="soft">Service Client</Button>
            </RouterLink>
            <RouterLink to="/Formulaire">
              <Button m="20px" variant="solid">Valider et passer au formulaire</Button>
            </RouterLink>
          </div>
        </form>
      </Box>
    </Grid>
  );
};

export default TwoSectionComponent;
