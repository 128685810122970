import { useState, useEffect } from 'react';
import { Container, Card, Text, Flex, RadioCards, Box, Button } from '@radix-ui/themes';
import planeImage from './../../assets/media/Stripe.jpeg';
import { API_URL } from './../../config/index';

const Step5 = ({ onPlanSelection, handleSubmit }) => {
  const [Premium, setPremium] = useState(null);

  useEffect(() => {
    // Event snippet for Paiement initié conversion page
    window.gtag('event', 'conversion', {'send_to': 'AW-16776215655/B-ROCKfwyPcZEOf4wr8-'});
  }, []);

  const HandlePremium = (value) => {
    setPremium(value);

    if (value === "1") {
      onPlanSelection("Standard");
    } else if (value === "2") {
      onPlanSelection("Alert");
    }
  };

  const handlePayment = async () => {
    const lineItems = [];

    if (Premium === "1") {
      handleSubmit();
      lineItems.push({
        price: 'price_1QOhGZIMXf9NMfTtS0OfzuY9', // Replace with the actual price ID for Pass Standard
        quantity: 1,
      });
    } else if (Premium === "2") {
      handleSubmit();
      lineItems.push({
        price: 'price_1QOhF8IMXf9NMfTtEUhT4uVy', // Replace with the actual price ID for Pass Alerte
        quantity: 1,
      });
    }

    try {
      const response = await fetch(`${API_URL}/Stripe/create-checkout-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ line_items: lineItems }),
      });

      if (response.ok) {
        const data = await response.json();
        window.location.href = data.url; // Redirect to the checkout session URL
      } else {
        const errorData = await response.json();
        // console.error('Error:', errorData.error);
      }
    } catch (error) {
      // console.error('Error:', error);
    }
  };

  return (
    <Container size="2">
      <Card m="20px" style={{ boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}>
        <Flex m="10px" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <Text size="6">Paiment Sécurisé par Stripe</Text>
          <img
            src={planeImage}
            alt="Description of the image"
            style={{
              width: '10%',
              height: '10%',
              objectFit: 'cover',
              borderRadius: 'var(--radius-3)',
            }}
          />
        </Flex>
        <Flex m="20px">
          <Card>
            <Box>
              <Flex>
                <Text size="5">Service :</Text>
              </Flex>
              <Text size="3">Traitement des demandes d'AVE, Support pendant le process inclus</Text>
            </Box>
          </Card>
        </Flex>
        <Flex p="20px">
          {/* <Text>Ce service comprend le contrôle, l'examen détaillé et la soumission de votre demande auprès du gouvernement canadien. Les taxes gouvernementales sont incluses.</Text> */}
        </Flex>
        <Flex p="20px">
          <Text>Sélectionner le plan d'expédition souhaité :</Text>
        </Flex>
        <Flex
          direction="column"
          gap="4"
          maxWidth="450px"
          style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: 'auto' }}
        >
          <RadioCards.Root color="violet" columns="2" onValueChange={(value) => HandlePremium(value)}>
            <RadioCards.Item value="1">
              <Text>Pass Standard</Text>
            </RadioCards.Item>
            <RadioCards.Item value="2">
              <Text>Pass Alerte</Text>
            </RadioCards.Item>
          </RadioCards.Root>

          {Premium === "1" && (
            <>
              <Card>
                <Box p="20px">
                  <Flex mb="10px">
                    <Text size="5">Délai de 72 heures ou moins</Text>
                  </Flex>
                  <Text>Voie prioritaire 72 heures</Text>
                </Box>
              </Card>
              <Flex maxWidth="400px">
                <Card m="20px">
                  <Flex m="20px" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                    <Text size="6">Paiment :</Text>
                  </Flex>
                  <Flex m="20px"><Text>Demande Ave - Pass Standard (Support inclus)</Text></Flex>
                  <Flex style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: 'auto' }} m="20px">
                    <Text size="7">43.92 € </Text>
                  </Flex>
                  <Flex m="20px" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Button size="3" color='red' onClick={handlePayment}>Proceder au paiment</Button>
                  </Flex>
                </Card>
              </Flex>
            </>
          )}
          {Premium === "2" && (
            <>
              <Card color="red">
                <Box p="20px">
                  <Flex mb="10px">
                    <Text size="5">Délai de 24 heures ou moins</Text>
                  </Flex>
                  <Text>Besoin d'un traitement plus rapide ? Optez pour "Pass Alerte" pour accélérer le traitement de votre demande AVE en 24 heures.</Text>
                </Box>
              </Card>
              <Flex maxWidth="400px">
                <Card m="20px">
                  <Flex m="20px" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                    <Text size="6">Paiment :</Text>
                  </Flex>
                  <Flex m="20px"><Text>Demande Ave - Pass Alerte (Support inclus)</Text></Flex>
                  <Flex style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: 'auto' }} m="20px">
                    <Text size="7">63.92 € </Text>
                  </Flex>
                  <Flex m="20px" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Button size="3" color='red' onClick={handlePayment}>Proceder au paiment</Button>
                  </Flex>
                </Card>
              </Flex>
            </>
          )}
        </Flex>
      </Card>
    </Container>
  );
};

export default Step5;
