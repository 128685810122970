import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import Box from '@mui/material/Box';
import { Button, Flex } from '@radix-ui/themes';
import Typography from '@mui/material/Typography';
import Success from './Success';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import { API_URL } from '../../config';
import LoadingComponent from './LoadingComponent'; // Import the loading component

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#DD3C3C',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#DD3C3C',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
    ...theme.applyStyles('dark', {
      borderColor: theme.palette.grey[800],
    }),
  },
}));

const QontoStepIconRoot = styled('div')(({ theme }) => ({
  color: '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  '& .QontoStepIcon-completedIcon': {
    color: '#DD3C3C',
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  ...theme.applyStyles('dark', {
    color: theme.palette.grey[700],
  }),
  variants: [
    {
      props: ({ ownerState }) => ownerState.active,
      style: {
        color: '#DD3C3C',
      },
    },
  ],
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
};

const steps = ['Type', 'Identité', 'Informations', 'Passport', 'Paiement'];

const fadeIn = {
  opacity: 1,
  transition: 'opacity 0.5s ease-in',
};

const slideOutToLeft = {
  opacity: 0,
  transform: 'translateX(-100%)',
  transition: 'transform 0.5s ease-out, opacity 0.5s ease-out',
};

const slideOutToRight = {
  opacity: 0,
  transform: 'translateX(100%)',
  transition: 'transform 0.5s ease-out, opacity 0.5s ease-out',
};

export default function CustomizedSteppers() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [loadingNext, setLoadingNext] = React.useState(false);
  const [loadingBack, setLoadingBack] = React.useState(false);
  const [selectedRadioValue, setSelectedRadioValue] = useState(null);
  const [selectedMinorValue, setSelectedMinorValue] = useState(null);
  const [step2RadioValue, setStep2RadioValue] = useState(null);
  const [transitionDirection, setTransitionDirection] = useState('');
  const [isStep3Valid, setIsStep3Valid] = useState(false);
  const [isStep4Valid, setIsStep4Valid] = useState(false);
  const [Showidentite, setShowidentite] = useState(true);
  const [selectedPlan, setSelectedPlan] = useState('Standard');

  const handlePlanSelection = (plan) => {
    console.log('Plan selected:', plan);
    setSelectedPlan(plan);
  };

  const [formValues, setFormValues] = useState({
    name: '',
    addressNumber: '',
    country: '',
    phoneNumber: '',
    email: '',
    companyName: 'N/A',
    memberId: 'N/A',
    province: 'N/A',
    firstName: '',
    street: '',
    postalCode: '',
    city: '',
    faxNumber: '',
    isPaid: false,
    declarationChecked: false,
    authorizationChecked: false,
  });

  const [formValues2, setFormValues2] = useState({
    DocumentType: 'N/A',
    PassCountryCode: 'N/A',
    PassNationality: 'N/A',
    Nom: 'N/A',
    Prenom: 'N/A',
    PassBirthCountry: 'N/A',
    PassNumero: 'N/A',
    Birthdate: null,
    PassExpiration: null,
    PassDelivery: null,
    BirthCity: 'N/A',
    sex: 'N/A',
    LocalCountry: 'N/A',
    MaritalStatus: 'N/A',
    PastVisa: 'N/A',
    IdolVisa: 'N/A',
    Occupation: 'N/A',
    JobCity: 'N/A',
    JobYearStart: 'N/A',
    JobCountry: 'N/A',
    EmployerOrUniversity: 'N/A',
    JobTitle: 'N/A',
    email: 'N/A',
    Phone: 'N/A',
    Language: 'N/A',
    AptNumber: 'N/A',
    AddressDetails: 'N/A',
    CodePostal: 'N/A',
    HomeCountry: 'N/A',
    HouseNumber: 'N/A',
    AddressDetails2: 'N/A',
    HomeCity: 'N/A',
    KnowWhen: false,
    TravelDate: null,
    RefusedBefore: false,
    RefusedBeforeDescription: 'N/A',
    additionalInfo: 'N/A',
    CrimePast: false,
    Tuberculose: false,
  });

  const handleFormValues2 = (values) => {
    setFormValues2((prevValues) => ({
      ...prevValues,
      ...values,
    }));
    console.log(formValues2);
  };

  const handleSubmit = async () => {
    const data = {
      natureVoyage: selectedRadioValue,
      formData: formValues,
      formData2: formValues2,
      identit2: step2RadioValue,
      minorrr: selectedMinorValue,
      selectedPlan: selectedPlan,
    };
    console.log(selectedPlan)
    // Function to replace null values with defaults
    const replaceNullValues = (obj) => {
      for (const key in obj) {
        if (obj[key] === null) {
          if (typeof obj[key] === 'string') {
            obj[key] = 'N/A';
          } else if (typeof obj[key] === 'boolean') {
            obj[key] = false;
          } else if (typeof obj[key] === 'object' && obj[key] !== null) {
            obj[key] = '2000-00-00'; // Default date string
          } else {
            obj[key] = 'N/A'; // Default for any other type
          }
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
          replaceNullValues(obj[key]); // Recursively handle nested objects
        }
      }
    };

    // Replace null values in the data object
    replaceNullValues(data);

    try {
      const response = await fetch(`${API_URL}/create-mymodel/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      // Log the raw response text for debugging
      const responseText = await response.text();
      console.log('Raw Response:', responseText);

      if (response.ok) {
        const responseData = JSON.parse(responseText);
        console.log('Success:', responseData);

        // Extract the id from the response data
        const id = responseData.id;

        // Save the id in a variable or localStorage
        localStorage.setItem('uniqueId', id);

        console.log('Unique ID:', id);
      } else {
        console.error('Error:', responseText);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleRadioChange = (value) => {
    setSelectedRadioValue(value);
  };

  const handleMinorChange = (value) => {
    setSelectedMinorValue(value);
  };

  const handleStep2RadioChange = (value) => {
    setStep2RadioValue(value);
    setShowidentite(value === 'Non' ? false : true);
  };

  const handleInputChange = (name, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSelectChange = (value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      selectedValue: value,
    }));
  };

  const handleCheckboxChange = (name, checked) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: checked,
    }));
  };

  const isStepOptional = (step) => {
    return false;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    setLoadingNext(true);
    setTransitionDirection('right');
    setTimeout(() => {
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }

      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
      setLoadingNext(false);
    }, 230); // Adjust the timeout to match the transition duration
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleBack = () => {
    setLoadingBack(true);
    setTransitionDirection('left');
    setTimeout(() => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      setLoadingBack(false);
    }, 500); // Adjust the timeout to match the transition duration
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setLoadingNext(true);
    setTransitionDirection('right');
    setTimeout(() => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped((prevSkipped) => {
        const newSkipped = new Set(prevSkipped.values());
        newSkipped.add(activeStep);
        return newSkipped;
      });
      setLoadingNext(false);
    }, 200); // Adjust the timeout to match the transition duration
  };

  const handleReset = () => {
    setLoadingNext(true);
    setTimeout(() => {
      setActiveStep(0);
      setLoadingNext(false);
    }, 500); // Adjust the timeout to match the transition duration
  };

  return (
    <Box sx={{ width: '100%' }}>
      {/* debug */}
      {/* <Button onClick={handleSubmit}>submit</Button> */}
      <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel StepIconComponent={QontoStepIcon} {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {loadingNext || loadingBack ? (
        <LoadingComponent />
      ) : (
        <React.Fragment>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Box sx={fadeIn}>
                <Success />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleReset} loading={loadingNext}>Reset</Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Box sx={activeStep === 0 ? fadeIn : (transitionDirection === 'right' ? slideOutToLeft : slideOutToRight)}>
                {activeStep === 0 && <Step1 onRadioChange={handleRadioChange} selectedRadioValue={selectedRadioValue} />}
              </Box>
              <Box sx={activeStep === 1 ? fadeIn : (transitionDirection === 'right' ? slideOutToLeft : slideOutToRight)}>
                {activeStep === 1 && <Step2
            onRadioChange={handleStep2RadioChange}
            selectedRadioValue={step2RadioValue}
            onMinorChange={handleMinorChange}
                />}

              </Box>
              <Box sx={activeStep === 2 ? fadeIn : (transitionDirection === 'right' ? slideOutToLeft : slideOutToRight)}>
                {activeStep === 2 && <Step3
                  formValues={formValues}
                  selectedValue={formValues.selectedValue}
                  onInputChange={handleInputChange}
                  onSelectChange={handleSelectChange}
                  onCheckboxChange={handleCheckboxChange}
                  onValidationChange={setIsStep3Valid}
                  Showidentite={Showidentite}
                />}
              </Box>
              <Box sx={activeStep === 3 ? fadeIn : (transitionDirection === 'right' ? slideOutToLeft : slideOutToRight)}>
                {activeStep === 3 && <Step4 onFormValuesChange={handleFormValues2} onValidation={setIsStep4Valid}/>}
              </Box>
              <Box sx={activeStep === 4 ? fadeIn : (transitionDirection === 'right' ? slideOutToLeft : slideOutToRight)}>
                {activeStep === 4 && <Step5 onPlanSelection={handlePlanSelection} handleSubmit={handleSubmit}/>}
              </Box>
              <Flex justify="center" align="center" m="20px">
                <Button
                    variant="outline"
                    size="3"
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    loading={loadingBack}
                    sx={{ mr: 1 }}
                >
                    Précédent
                </Button>
                {isStepOptional(activeStep) && (
                    <Button color="inherit" onClick={handleSkip} loading={loadingNext} sx={{ mr: 1 }}>
                    Skip
                    </Button>
                )}
                <Flex ml="50px">
                {activeStep !== 4 && (
                    <Button
                        size="3"
                        onClick={handleNext}
                        disabled={(activeStep === 0 && !selectedRadioValue) || (activeStep === 1 && !step2RadioValue) || (activeStep === 2 && !isStep3Valid) || (activeStep === 3 && !isStep4Valid)}
                        loading={loadingNext}
                    >
                        {activeStep === steps.length - 1 ? 'Finish' : 'Suivant'}
                    </Button>
                )}
                {/* for easier debugging */}
                {/*<Button
                    size="3"
                    onClick={handleNext}
                    loading={loadingNext}
                >
                    {activeStep === steps.length - 1 ? 'Finish' : 'Suivant'}
                </Button>*/}
                </Flex>
                </Flex>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </Box>
  );
}
