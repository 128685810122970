import React from 'react';
import logo from "./../assets/media/logonotext.png";
import { TextField, Text, Box,Grid,Button,Flex,Link } from '@radix-ui/themes';
import { FaHome, FaFileAlt, FaInfoCircle, FaBlog } from 'react-icons/fa';
import './navbar.css'


const Footer = () => {

  return (
    <div>
      <Grid columns={{ initial: '1', md: '2' }} gap="3" width="auto" mt="150px">
        <Box height="64px" m="40px">
          <div>
            <Text weight="bold" size="5" >
              Abonnez vous a notre newsletter :
            </Text>
          </div>
          <div style={{ marginTop: '15px' }}>
            <Text weight="regular" size="2" >
            Soyez informé des actualités de voyage et des nouvelles.
            </Text>
          </div>
        </Box>
        <Box height="64px" m="40px">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TextField.Root
              color = "gray"
              variant="soft"
              placeholder="Votre email"
              style={{ flexGrow: 1, marginRight: '20px', color: 'var(--gray-10)' }}
            />
            <Button  color = "gray"variant="soft" style={{ marginLeft: 'auto', color: 'var(--gray-10)' }}>
              s'abonner
            </Button>
          </div>
        </Box>
      </Grid>
      <Grid columns={{ initial: '1', md: '2' }} gap="3" width="auto">
        <Box m="20px" height="auto">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={logo}
              alt="Description of the image"
              style={{
                width: '20%',
                height: 'auto',
                objectFit: 'cover',
                borderRadius: 'var(--radius-3)',
                marginRight: '10px', // Add some space between the logo and the text
              }}
            />
            <Text weight="bold" size="4">Canada AVE</Text>
          </div>
          
        </Box>
        <Box mt="0px" mb="200px" m="80px" height="64px">
          <Link href="/#">
            <Flex justify="center" align="bottom" mb="20px">
                <Button href="/#" size="4" variant="outline" style={{ width: '100%' }}>
                  <Flex align="center" justify="start">
                    <FaHome style={{ marginRight: '5px' }} /> {/* Add the home icon to the button */}
                    Acceuil
                  </Flex>
                </Button>
            </Flex>
          </Link>
          <Link href="/Formulaire">
            <Flex justify="center" align="center" mb="20px">
              <Button size="4" variant="outline" style={{ width: '100%' }}>
                <Flex align="center" justify="start">
                  <FaFileAlt style={{ marginRight: '5px' }} /> {/* Add the file alt icon to the button */}
                  AVE - Formulaire
                </Flex>
              </Button>
            </Flex>
          </Link>
          <Link href="/Contact">
            <Flex justify="center" align="center" mb="20px">
              <Button size="4" variant="outline" style={{ width: '100%' }}>
                <Flex align="center" justify="start">
                  <FaInfoCircle style={{ marginRight: '5px' }} /> {/* Add the info circle icon to the button */}
                  Information de voyage
                </Flex>
              </Button>
            </Flex>
          </Link>
          <Link href="/FAQ">
            <Flex justify="center" align="center">
              <Button size="4" variant="outline" style={{ width: '100%' }}>
                <Flex align="center" justify="start">
                  <FaBlog style={{ marginRight: '5px' }} /> {/* Add the blog icon to the button */}
                  Blog
                </Flex>
              </Button>
            </Flex>
          </Link>
        </Box>
      </Grid>
      <Flex m="20px">
      <Text style={{ margin: '50px' }}>AVE Canada : Nous sommes un site privé, pas un service gouvernemental

Lorsqu'il s'agit de voyager au Canada, il est essentiel de comprendre les exigences pour obtenir une Autorisation de Voyage Électronique (AVE). Chez canadaave-eta , nous tenons à être transparents : nous sommes un site privé et non affilié au gouvernement canadien.

Qu’est-ce qu’une AVE ?

Une AVE est une autorisation électronique exigée pour les voyageurs venant de pays dispensés de visa. Ce document est requis pour entrer au Canada par avion. Vous pouvez obtenir votre AVE directement sur le site officiel du gouvernement canadien.

Quel est notre rôle ?

Nous proposons un service privé pour aider les voyageurs à obtenir leur AVE facilement et sans stress. Voici ce que nous offrons :

Un accompagnement personnalisé : Nous simplifions le processus pour ceux qui trouvent les démarches administratives complexes.

Une assistance en cas de problème : Notre équipe est disponible pour répondre à vos questions et résoudre les éventuels problèmes.

Une vérification approfondie des informations : Nous nous assurons que votre demande est correcte pour éviter tout refus.

Pourquoi passer par un site privé ?

Certaines personnes préfèrent utiliser un service privé pour :

Éviter les erreurs lors de la demande.

Recevoir une assistance rapide et efficace.

Gagner du temps grâce à un processus simplifié.

Transparence sur les frais

En utilisant notre service, vous payez des frais supplémentaires pour bénéficier de notre accompagnement. Il est important de noter que ces frais sont distincts de ceux demandés par le gouvernement canadien pour l’AVE.</Text></Flex>
    </div>
  );
};
export default Footer;